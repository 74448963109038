import * as types from '../actions/authActions';

const initialState = {
  fullName: '',
  avatar: '',
  accessToken: null,
  userData: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATE:
      return {
 fullName: action.user.name, avatar: action.user.avatar, accessToken: action.user.accessToken, userData: action.user.userData,
};
    case types.AUTHENTICATE_LOGOUT_AUTH:
      return { ...initialState };
    case types.AUTHENTICATE_ERROR_AUTH:
      return { error: action.error };
    default:
      return state;
  }
};

export default authReducer;
