import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Layout from '../../../Layout/index';
import PMSDashboard from '../../../Dashboards/PmsDashboard/index';
import UI from './UI';
import Mail from '../../../Mail/index';
import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';
import Forms from './Forms';
import Tables from './Tables';
import Charts from './Charts';
import Maps from './Maps';
import Account from './Account';
import ECommerce from './ECommerce';
import DefaultPages from './DefaultPages';
import Documentation from './Documentation';
import Wallet from '../../../Dashboards/Wallet';
import { ProtectedRoute } from '../../../../shared/components/ProtectedRoute';
import Roles from './Roles';
import Users from './Users';
import PropertyType from './PropertyType';
import DocumentType from './DocumentType';
import Properties from './Properties';
import PropertyUnit from './PropertyUnit';
import Settings from './Settings';
import { getSettings, getSettingValue } from '../../../../shared/helpers/getDatas';
import { getAccessToken } from '../../../../utils/helpers';
import axios from 'axios';
import Invoice from './Invoice';
import InvoiceStateContext from '../../../Invoice/Components/pdf/context/stateContext';
import TransactionCategory from './TransactionCategory';
import Transaction from './Transaction';
import Utilities from './Utilities';
import PaymentMethod from './PaymentMethod';
import Lease from './Lease';

export default () => {
  const token = getAccessToken();
  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    getSettings(token, source)
      .then((response) => {
        const settingsData = response?.data?.settings;
        let settingsDataForStorage = {};
        if (settingsData) {
          settingsData.map((item, index) => {
            settingsDataForStorage[item.config_title] = getSettingValue(settingsData, item.config_title);
          });
          settingsDataForStorage.light = getSettingValue(settingsData, 'logo');
          settingsDataForStorage.dark = getSettingValue(settingsData, 'dark_logo');
        }
        /*const light_logo = getSettingValue(settingsData, 'logo');
        const dark_logo = getSettingValue(settingsData, 'dark_logo');
        const favicon = getSettingValue(settingsData, 'favicon');
        const company_name = getSettingValue(settingsData, 'company_name');
        const companyEmail = getSettingValue(settingsData, 'company_email');
        const companyAddress = getSettingValue(settingsData, 'company_address');*/
        localStorage.setItem('pmfSettings', JSON.stringify(settingsDataForStorage));
      });
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <Layout/>
      <ContainerWrap>
        <ProtectedRoute>
          <Route path="/dashboard" component={PMSDashboard}/>
          <Route path="/roles" component={Roles}/>
          <Route path="/property-type" component={PropertyType}/>
          <Route path="/document-type" component={DocumentType}/>
          <Route path="/properties" component={Properties}/>
          <Route path="/property-unit" component={PropertyUnit}/>
          <Route path="/utilities" component={Utilities}/>
          <Route path="/payment-method" component={PaymentMethod}/>
          <Route path="/users" component={Users}/>
          <Route path="/lease" component={Lease}/>
          <InvoiceStateContext>
            <Route path="/invoice" component={Invoice}/>
          </InvoiceStateContext>
          <Route path="/transaction-category" component={TransactionCategory}/>
          <Route path="/transactions" component={Transaction}/>
          <Route path="/settings" component={Settings}/>
          {/* <Route path="/e_commerce_dashboard" component={Commerce} />*/}
          {/* <Route path="/connect_wallet" component={ConnectWallet} /> */}
          {/* <Route exact path="/app_dashboard" component={AppDashboard} /> */}
          {/* <Route path="/booking_dashboard" component={BookingDashboard} /> */}
          {/* <Route exact path="/finance_dashboard" component={FinanceDashboard} /> */}
          {/* <Route path="/fitness_dashboard" component={FitnessDashboard} /> */}
          {/*<Route path="/ui" component={UI}/>
          <Route path="/mail" component={Mail}/>
          <Route path="/chat" component={Chat}/>
          <Route path="/todo" component={Todo}/>
          <Route path="/forms" component={Forms}/>
          <Route path="/tables" component={Tables}/>
          <Route path="/charts" component={Charts}/>
          <Route path="/maps" component={Maps}/>*/}
          <Route path="/account" component={Account}/>
          {/*<Route path="/e-commerce" component={ECommerce}/>
          <Route path="/documentation" component={Documentation}/>
          <Route path="/wallet" component={Wallet}/>
          <Route path="/default_pages" component={DefaultPages}/>*/}
        </ProtectedRoute>
      </ContainerWrap>
    </>
  );
};

// region STYLES

const ContainerWrap = styled.div`
    padding-top: 90px;
    min-height: 100vh;
    transition: padding-left 0.3s;

    ${paddingLeft}: 0;

    background: ${colorBackgroundBody};

    @media screen and (min-width: 576px) {
        ${paddingLeft}: 250px;
    }

    @media screen and (max-width: 576px) {
        padding-top: 150px;
    }
`;

// endregion
