/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Payment method field is required.';
  }
  if (!values.status) {
    errors.status = 'Status field is required.';
  }
  return errors;
};

export default validate;
