import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import {UserRoles} from "../../../../shared/constants/userroles";
import {PropertyTypeList} from "../../../PropertyType";
import AddPropertyType from "../../../PropertyType/Components/add";

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/property-type/list" component={PropertyTypeList}/>
            <Route path="/property-type/add" component={AddPropertyType}/>
            <Route path="/property-type/edit/:id" component={AddPropertyType}/>
        </PermissionRoute>
    </Switch>
);
