import {
  FACILITIES,
  GET_COUNTRIES,
  GET_DOCUMENT_TYPES,
  GET_LEASED_PROPERTY_BY_PROPERTY_UNIT,
  GET_PROPERTIES,
  GET_PROVINCES,
  GET_ROLES,
  GET_SETTINGS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_BY,
  INVOICES,
  PROPERTY_TYPES,
  PROPERTY_UNITS,
  TRANSACTION_CATEGORIES_BY_TYPE,
  GET_LEASED_PROPERTY,
  GET_USERS,
  PAYMENT_METHOD,
  UTILITY_BY_LEASED_ID,
  FEES,
  GET_PROPERTY_DETAILS,
  TRANSACTIONS,
  GET_TRANSACTION_BY
} from '../../containers/App/Router/api_routs';
import { get } from '../../utils/api/base';
import { generateFormInitialValue, ucFirst } from './index';
import { UserRoles } from '../constants/userroles';
import { range } from '../../shared/helpers';
import { Status } from '../constants/commonConstnt';

export const getCountriesList = async (token, source, setCountries) => {
  await get(GET_COUNTRIES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const countryOptions = [];
      const countryData = response?.data?.countries;
      if (countryData) {
        for (const i in countryData) {
          countryOptions[i] = {
            value: countryData[i].num_code,
            label: countryData[i].en_short_name,
          };
        }
      }
      setCountries(countryOptions);
    });
};

export const getProvincesList = async (token, source, setProvinces) => {
  await get(GET_PROVINCES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const provinceOptions = [];
      const provinceData = response?.data?.provinces;
      if (provinceData) {
        for (const j in provinceData) {
          provinceOptions[j] = {
            value: provinceData[j].province_number,
            label: provinceData[j].province_name,
          };
        }
      }
      setProvinces(provinceOptions);
    });
};

export const getRolesList = async (token, source, setRoles, setSelectedRoleOption, setShowSection) => {
  await get(GET_ROLES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const rolesOptions = [];
      const rolesData = response?.data?.roles?.data;
      if (rolesData) {
        for (const l in rolesData) {
          rolesOptions[l] = {
            value: rolesData[l].id,
            label: rolesData[l].name,
          };
        }
      }
      setRoles(rolesOptions);
      setSelectedRoleOption({
        value: '3',
        label: 'tenant',
      });
      setShowSection(true);
    });
};

export const getDocumentTypeList = async (token, source, setDocumentTypes) => {
  await get(GET_DOCUMENT_TYPES + '?status=1', {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const documentTypeOptions = [];
      const documentTypeData = response?.data?.document_types?.data;
      if (documentTypeData) {
        for (const k in documentTypeData) {
          documentTypeOptions[k] = {
            value: documentTypeData[k].id,
            label: documentTypeData[k].document_type,
          };
        }
      }
      setDocumentTypes(documentTypeOptions);
    });
};

export const getPropertyTypes = async (token, source, setPropertyTypes) => {
  await get(PROPERTY_TYPES + '?status=1', {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      let propertyTypeOptions = [{
        value: '',
        label: 'Property Type'
      }];
      let propertyTypeData = response?.data?.property_types?.data;
      if (propertyTypeData) {
        for (let j in propertyTypeData) {
          let index = parseInt(j) + 1;
          propertyTypeOptions[index] = {
            value: propertyTypeData[j].id,
            label: propertyTypeData[j].property_type
          };
        }
      }
      setPropertyTypes(propertyTypeOptions);
    });
};

export const getPropertyList = async (token, source, setProperties) => {
  await get(GET_PROPERTIES + '?property_status=1', {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const propertiesOptions = [{
        value: '',
        label: 'Property'
      }];
      const propertiesData = response?.data?.properties?.data;
      if (propertiesData) {
        for (const l in propertiesData) {
          let index = parseInt(l) + 1;
          propertiesOptions[index] = {
            value: propertiesData[l].id,
            label: propertiesData[l].name,
          };
        }
      }
      setProperties(propertiesOptions);
    });
};

export const getPropertyPrefix = async (token, property_id) => {
  let prefix = '';
  await get(GET_PROPERTY_DETAILS + property_id, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
  })
    .then((response) => {
      const propertyData = response?.data?.property;
      if (propertyData) {
        prefix = propertyData.invoice_prefix;
      }
    });
  return prefix;
};

export const getLeasedPropertyList = async (token, source, setProperties) => {
  await get(GET_LEASED_PROPERTY, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const propertiesOptions = [{
        value: '',
        label: 'Select Property'
      }];
      const propertiesData = response?.data?.property_leases.data;
      if (propertiesData) {
        for (const l in propertiesData) {
          let index = parseInt(l) + 1;
          propertiesOptions[index] = {
            value: propertiesData[l].id,
            label: propertiesData[l].name,
          };
        }
      }
      setProperties(propertiesOptions);
    });
};

export const getUserDetails = async (token, id, source, setShowSection, setInitValue) => {
  await get(GET_USER_DETAILS + `${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      let userData = response?.data?.user_details;
      //let userObjData = response?.data;
      const convertSelectDefault = {
        primary_country: 'primary_country_id',
        secondary_country: 'secondary_country_id',
        primary_province: 'primary_state_id',
        secondary_province: 'secondary_state_id',
      };
      const mapValue = {
        primary_country: 'en_short_name',
        secondary_country: 'en_short_name',
        primary_province: 'province_name',
        secondary_province: 'province_name',
      };
      const users = userData.users;
      delete userData.users;
      userData.role = {
        value: users.role,
        label: users.user_role.name
      };
      if(users.hasOwnProperty('status')){
        userData.status = users.status;
      }
      let data = generateFormInitialValue(userData, convertSelectDefault, mapValue);
      let documents = [];
      for (const key in data.documents) {
        documents[key] = generateFormInitialValue(data.documents[key], { document_types: 'document_type' }, { document_types: 'document_type' });
      }
      data.documents = documents;

      setShowSection(false);
      if (data.role.value === UserRoles.TENANT) {
        setShowSection(true);
      }
      setInitValue(data);
    });
};

export const getUnitList = async (token, propertyId, index, units, setUnits, leasedOnly = true, nonLeased = false) => {
  let unitURL = PROPERTY_UNITS;
  if (propertyId) {
    unitURL += `?property_id=${propertyId}&leased=${leasedOnly}&nonLeased=${nonLeased}`;
  } else {
    unitURL += `?leased=${leasedOnly}&nonLeased=${nonLeased}`;
  }
  await get(unitURL, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      const unitOptions = [{
        value: '',
        label: 'Select Unit'
      }];
      const unitData = response?.data?.units?.data;
      if (unitData) {
        for (const l in unitData) {
          let index = parseInt(l) + 1;
          unitOptions[index] = {
            value: unitData[l].id,
            label: unitData[l].unit_name,
          };
        }
      }
      units[index] = unitOptions;
      setUnits(units);
    });
  return units;
};

export const setInitSettingsValue = async (token, source, setInitValue) => {
  await get(GET_SETTINGS, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      cancelToken: source.token
    },
  })
    .then((response) => {
      const options = [];
      const optionsData = response?.data?.settings;
      if (optionsData) {
        for (const i in optionsData) {
          options[optionsData[i].config_title] = optionsData[i].config_value;
          if (optionsData[i].config_value_url !== undefined) {
            options[optionsData[i].config_title + '_url'] = optionsData[i].config_value_url;
          }
        }
      }
      let data = { ...options };
      setInitValue(data);
    });
};

export const getSettings = (token, source) => {
  return get(GET_SETTINGS, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      cancelToken: source.token
    },
  });
};

export const getSettingValue = (data, config_key) => {
  if (data) {
    for (let i in data) {
      if (data[i].config_title == config_key) {
        return data[i].config_value_url ? data[i].config_value_url : data[i].config_value;
      }
    }
  }
  return '';
};

export const getFacilityList = async (token) => {
  let facilityData = [];
  const facilityOptions = [];
  await get(FACILITIES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      facilityData = response?.data?.facilities.data;
      if (facilityData) {
        for (const l in facilityData) {
          facilityOptions[l] = {
            value: facilityData[l].id,
            label: facilityData[l].facility,
          };
        }
      }
    });
  return { raw: facilityData, options: facilityOptions};
};

export const getUtilityBy = async (token, params) => {
  let facilityData = [];
  const facilityOptions = [];
  await get(UTILITY_BY_LEASED_ID, {
    params: { ...params },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      facilityData = response?.data?.utilities.data;
      if (facilityData) {
        for (const l in facilityData) {
          facilityOptions[l] = {
            value: facilityData[l].facility.id,
            label: facilityData[l].facility.facility,
          };
        }
      }
    });
  return { raw: facilityData, options: facilityOptions};
};

export const getRentByPropertyUnit = async (token, property_id, unit_id, setRent = null, setUser, setTenant) => {
  let url = GET_LEASED_PROPERTY_BY_PROPERTY_UNIT + '/' + property_id + '/' + unit_id;
  let rent = 0;
  let lease = [];
  await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      const options = [];
      lease = response?.data?.property_lease;
      if (setRent) {
        setRent(lease?.amount);
      }
      setUser(lease?.user_id);
      setTenant(lease.user.name);
    });
  return lease;
};

export const getInvoice = async (token, property_id = null, unit_id = null, limit = 20, order = 'DESC', edit_id = null, user_id = null) => {
  let data = [];
  let params = [];
  params['property_id'] = property_id;
  params['unit'] = unit_id;
  params['edit_id'] = edit_id;
  params['user_id'] = user_id;
  params['limit'] = limit;
  params['order'] = order;
  await get(INVOICES, {
    params: { ...params },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      data = response?.data?.invoices.data;
    });
  return data;
};

export const getInvoiceDetail = async (token, source, id) => {
  let data = {};
  await get(INVOICES + `/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      data = response?.data?.invoice;
    });
  return data;
};

export const getSettingsValue = (key) => {
  let settings = localStorage.getItem('pmfSettings');
  if (settings) {
    settings = JSON.parse(settings);
    return settings[key] ?? '';
  }
  return '';
};

export const getTransactionCategory = async (selectedOption, token, setCategories) => {
  let transactionCategory = await get(`${TRANSACTION_CATEGORIES_BY_TYPE}/${selectedOption.value}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let options = [];
  if (transactionCategory) {
    transactionCategory = transactionCategory.data.transaction_categories;
    for (const i in transactionCategory) {
      options[i] = {
        value: transactionCategory[i].id,
        label: transactionCategory[i].name,
      };
    }
    setCategories(options);
  }
  return options;
};

export const getCurrentUserDetails = async (token, user) => {
  return await get(GET_USER_DETAILS_BY + `${user.userData.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
};

export const getUsers = async (token, set, params) => {
  let url = GET_USERS;
  if (set) {
    url += params;
  }
  let results = await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let users = [];
  if (results) {
    let options = [];
    let data = results?.data?.users?.data;
    users = data;
    for (const i in data) {
      options[i] = {
        value: data[i].id,
        label: data[i].name,
      };
    }
    if (set) {
      set(options);
    }
  }
  return users;
};

export const utilities = async (token, setData, setTotalData, setIsFetching, params) => {
  let urlParams = params ?? '';
  const result = await get(FACILITIES + urlParams, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let facilityData = [];
  if (result) {
    facilityData = result?.data?.facilities.data;
    if (facilityData) {
      facilityData.map((item) => {
        item.status = Status[item.status];
      });
    }
    if (setData) {
      setData(facilityData);
    }
    if (setTotalData) {
      setTotalData(result?.data?.facilities?.total);
    }
    if (setIsFetching) {
      setIsFetching(false);
    }
  }
  return facilityData;
};

export const paymentMethods = async (token, setData) => {
  const result = await get(PAYMENT_METHOD + '?status=1', {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let paymentMethodData = [];
  if (result) {
    paymentMethodData = result?.data?.paymentMethods.data;
    if (paymentMethodData) {
      let options = [];
      for (const i in paymentMethodData) {
        options[i] = {
          value: paymentMethodData[i].id,
          label: paymentMethodData[i].name,
        };
      }
      if (setData) {
        setData(options);
      }
    }
  }
  return paymentMethodData;
};

export const getFees = async (token, property_lease_id = 0, fee_type = '', limit = '-1') => {
  let feeData = {};
  let params = [];
  params['lease_id'] = property_lease_id;
  params['fee_type'] = fee_type;
  params['limit'] = limit;

  await get(FEES, {
    params: { ...params },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
  })
    .then((response) => {
      feeData = response?.data?.fees.data;
    });
  return feeData;
};

export const getTransaction = async (token, id = null, limit = null, currentTransaction = null) => {
  let data = {};
  let params = [];
  params['invoice_id'] = id;
  params['not_include'] = currentTransaction;
  params['limit'] = limit;
  await get(TRANSACTIONS, {
    params: { ...params },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
  })
    .then((result) => {
      data = result?.data?.transactions?.data;
    });
  return data;
};

export const getTransactionBy = async (token, params) => {
  let data = [];

  await get(GET_TRANSACTION_BY, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      data = response?.data?.transaction;
    });
  return data;
};
