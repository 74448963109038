/* eslint-disable */

import { get } from '../../../utils/api/base';
import {  GET_USER_DETAILS_BY } from '../../App/Router/api_routs';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import { UserRoles } from '../../../shared/constants/userroles';

const getUserDetailsBy = (url) => {
  const auth = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
  const token = auth?.accessToken;
  return get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
};
const checkAlreadyExist = async (api_url, values) => {
  const url = api_url;
  let finalUrl = url + values.primary_email;
  if (values.hasOwnProperty('id')) {
    finalUrl = url + values.user_id;
  }
  const response = await getUserDetailsBy(finalUrl);
  const user_details = response?.data?.user_details;
  return !!((user_details && !values.hasOwnProperty('id')) ||
    (user_details && values.hasOwnProperty('id') && user_details.id === values.user_id
      && user_details.email !== values.primary_email && ((await getUserDetailsBy(url + values.primary_email))?.data?.user_details)));
};

let oldEmail = '';
const validate = async (values, e) => {
  await sleep(100);
  const errors = {};
  if (!values.primary_name) {
    errors.primary_name = 'Name field is required.';
  }
  if (!values.primary_email) {
    errors.primary_email = 'Email field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.primary_email)) {
    errors.primary_email = 'Invalid email address';
  }
  if(oldEmail !== values.primary_email) {
    oldEmail = values.primary_email;
    if (values.primary_email) {
      await checkAlreadyExist(GET_USER_DETAILS_BY, values)
        .then(response => {
          if (response) {
            errors.primary_email = 'This email address already exists. Please try another email.';
          }
        });
    }
  }
  if (!values.primary_contact_number) {
    errors.primary_contact_number = 'Primary contact number field is required.';
  }
  if (!values.primary_address_line1) {
    errors.primary_address_line1 = 'Primary address line 1 field is required.';
  }
  if (!values.primary_country_id) {
    errors.primary_country_id = 'Primary country field is required.';
  }
  if (!values.primary_state_id) {
    errors.primary_state_id = 'Primary state field is required.';
  }
  if (!values.primary_city) {
    errors.primary_city = 'Primary city field is required.';
  }
  if (!values.role) {
    errors.role = 'Roles field is required.';
  }

  //Tenant User Validation
  if (values.role && values.role.value === UserRoles.TENANT) {

    /***************************** document validation start ***********************************/
    if (!values.documents || !values.documents.length) {
      errors.documents = 'Required at least one document.';
    }
    if (values.documents) {
      const documentsErrors = [];

      values.documents.forEach((document, index) => {
        const documentErrors = {};

        if (!document.document_type) {
          documentErrors.document_type = 'Document Type field is Required.';
        }

        if (!document.document) {
          documentErrors.document = 'File field is Required.';
        }

        if (Object.keys(documentErrors).length) {
          documentsErrors[index] = documentErrors;
        }
      });

      if (documentsErrors.length) {
        errors.documents = documentsErrors;
      }
    }

  }//Tenant User Validation end

  return errors;
};

export default validate;
