import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { propertyUnitCreate, propertyUnitUpdate } from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { get } from '../../../utils/api/base';
import {  GET_PROPERTIES, PROPERTY_UNITS } from '../../App/Router/api_routs';
import axios from 'axios';
import { FormGroupDescription } from '../../../shared/components/form/FormElements';
import { useParams } from 'react-router-dom';
import { generateFormInitialValue } from '../../../shared/helpers';
import styled from 'styled-components';
import { colorAccent } from '../../../utils/palette';

const AddPropertyUnit = () => {

  const token = getAccessToken();
  const [properties, setProperties] = useState([]);
  const { id } = useParams();
  const [initValue, setInitValue] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property Type"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const getProperties = async (source) => {
    await get(GET_PROPERTIES+'?property_status=1', {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
      cancelToken: source.token
    })
      .then((response) => {
        let propertyOptions = [];
        let propertyData = response?.data?.properties?.data;
        if (propertyData) {
          for (let i in propertyData) {
            propertyOptions[i] = {
              value: propertyData[i].id,
              label: propertyData[i].name
            };
          }
        }
        setProperties(propertyOptions);
      });
  };

  const getPropertyUnitDetails = async (id, source) => {
    await get(PROPERTY_UNITS + `/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
      cancelToken: source.token
    })
      .then((response) => {
        let propertyUnitData = response?.data?.unit;
        const convertSelectDefault = {
          property: 'property_id',
        };
        const mapValue = {
          property: 'name',
        };
        const data = generateFormInitialValue(propertyUnitData, convertSelectDefault, mapValue);
        setInitValue(data);
        setIsFetching(false);
      });
  };

  const handleAddPropertyUnit = async (values, form) => {
    let data = [];
    const convertIntKeys = ['status', 'total_area'];
    Object.keys(values)
      .forEach(function (key) {
        if (typeof (values[key]) === 'object' && (values[key]) !== null) {
          data[key] = values[key].value;
          if (convertIntKeys.includes(key)) {
            data[key] = parseInt(values[key].value);
            if (2 === data[key]) {
              data[key] = 0;
            }
          }
        } else {
          data[key] = values[key];
          if (convertIntKeys.includes(key)) {
            data[key] = parseInt(values[key]);
          }
        }
      });
    data = { ...data };
    if (data && !id) {
      await propertyUnitCreate(data, token)
        .then((result) => {
          if (result.data.status === 201) {
            show('success', result.data.message);
            form.reset();
            for (let key in data) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        }).catch(function (e) {
          show('danger', e.response.data.message);
        });
    }
    if (data && id) {
      await propertyUnitUpdate(id, data, token)
        .then((result) => {
          if (result.data.status === 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        }).catch(function (e) {
        show('danger', e.response.data.message);
      });
    }
  };

  useEffect(async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    await getProperties(source);
    if (id) {
      await getPropertyUnitDetails(id, source);
    }
    setIsFetching(false);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {

  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Property Unit Add Form</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          {isFetching ?
            <Card>
              <CardBody>
                <div className="text-center">
                  <TableSpinner animation="border"/>
                </div>
              </CardBody>
            </Card>
            :
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Unit Details</CardTitle>
                  {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                </CardTitleWrap>
                <Form
                  onSubmit={(values, e) => handleAddPropertyUnit(values, e)}
                  initialValues={initValue}
                  validate={validate}>
                  {({
                    handleSubmit,
                    form,
                    touched
                  }) => {
                    return (
                      <FormContainer onSubmit={handleSubmit}>
                        <FormGroup>
                          <FormGroupLabel>Unit Name</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="unit_name"
                              component={FormField}
                              type="text"
                              placeholder="Property Unit Name"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Property</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="property_id"
                              component={renderSelectField}
                              options={properties}
                              placeholder="Property"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Total Area</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="total_area"
                              component={FormField}
                              type="number"
                              placeholder="Area"
                            />
                          </FormGroupField>
                          <FormGroupDescription>
                            (Area in square Feet)
                          </FormGroupDescription>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Status</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="status"
                              component={renderSelectField}
                              options={[{
                                value: 1,
                                label: 'Active'
                              }, {
                                value: 2,
                                label: 'Inactive'
                              }]}
                              placeholder="Status"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormButtonToolbar>
                          <Button variant="primary" type="submit">Submit</Button>
                          <Button variant="secondary" type="button" onClick={form.reset}>
                            Cancel
                          </Button>
                        </FormButtonToolbar>
                      </FormContainer>
                    );
                  }}
                </Form>
              </CardBody>
            </Card>
          }
        </Col>
      </Row>
    </Container>
  );
};

export default AddPropertyUnit;

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
