import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {RoleList} from '../../../Roles/index';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import AddRole from "../../../Roles/Components/add";
import {UserRoles} from "../../../../shared/constants/userroles";

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/roles/list" component={RoleList}/>
            <Route path="/roles/add" component={AddRole}/>
            {/* <Route path="/users/edit/" component={Carousel} /> */}
        </PermissionRoute>
    </Switch>
);