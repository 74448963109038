import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserRoles } from '../../../../shared/constants/userroles';
import { TransactionList } from '../../../Transaction';
import CreateTransaction from '../../../Transaction/Components/add';

export default () => (
  <Switch>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/transactions/list" component={TransactionList}/>
      <Route path="/transactions/add" component={CreateTransaction}/>
      <Route path="/transactions/edit/:id" component={CreateTransaction}/>
    </PermissionRoute>
  </Switch>
);
