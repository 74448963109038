import { useSelector } from 'react-redux';

export default function UseAuth() {
    const { user } = useSelector(state => state);
    let auth = user;
    if (!user.accessToken) {
        auth = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
    }
    if (auth && auth.accessToken) {
        return auth;
    }
    return user;
}
