import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import {
  MaterialFormContainer,
  MaterialTextField,
  MaterialFormLabel,
} from '@/shared/components/form/MaterialFormElements';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const renderTextField = ({
  input,
  label,
  meta: {
    touched,
    error
  },
}) => (
  <MaterialTextField
    label={label}
    error={touched && error}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};

const ProfileSettings = ({
    onSubmit,
    initialValues,
    t,
    countries,
    provinces
  }) => (
    <Form onSubmit={(values, e) => onSubmit(values, e)} initialValues={initialValues}>
      {({
        handleSubmit,
        form
      }) => (
        <MaterialFormContainer onSubmit={handleSubmit}>
          <div>
            <MaterialFormLabel>{t('Full Name')}</MaterialFormLabel>
            <Field
              name="name"
              component={renderTextField}
              placeholder={t('Full Name')}
            />
          </div>
          <div>
            <MaterialFormLabel>{t('Email')}</MaterialFormLabel>
            <Field
              name="email"
              component={renderTextField}
              placeholder="example@mail.com"
              type="email"
            />
          </div>
          <div>
            <MaterialFormLabel>{t('Phone')}</MaterialFormLabel>
            <Field
              name="primary_contact_number"
              component={renderTextField}
              placeholder={t('Phone')}
              type="phone"
            />
          </div>
          <div>
            <MaterialFormLabel>{t('Address 1')}</MaterialFormLabel>
            <Field
              name="primary_address_line1"
              component={renderTextField}
              placeholder={t('Address 1')}
              multiline
              rowsMax="4"
            />
          </div>
          <div>
            <MaterialFormLabel>{t('Address 2')}</MaterialFormLabel>
            <Field
              name="primary_address_line2"
              component={renderTextField}
              placeholder={t('Address 2')}
              multiline
              rowsMax="4"
            />
          </div>
          <FormGroup>
            <MaterialFormLabel>{t('Country')}</MaterialFormLabel>
            <Field
              name="primary_country_id"
              component={renderSelectField}
              options={countries}
              // placeholder={t('Country')}
            />
          </FormGroup>
          <FormGroup>
            <MaterialFormLabel>{t('Province')}</MaterialFormLabel>
            <Field
              name="primary_state_id"
              component={renderSelectField}
              options={provinces}
              // placeholder={t('Province')}
            />
          </FormGroup>
          <div>
            <MaterialFormLabel>{t('City')}</MaterialFormLabel>
            <Field
              name="primary_city"
              component={renderTextField}
              placeholder={t('City')}
            />
          </div>
          <div>
            <MaterialFormLabel>{t('Zip Code')}</MaterialFormLabel>
            <Field
              name="primary_zip_code"
              component={renderTextField}
              placeholder={t('Zip Code')}
            />
          </div>
          <FormButtonToolbar>
            <Button variant="primary" type="submit">{t('Update profile')}</Button>
            <Button variant="secondary" type="button" onClick={form.reset}>
              {t('Cancel')}
            </Button>
          </FormButtonToolbar>
        </MaterialFormContainer>
      )
      }
    </Form>
  )
;

export default ProfileSettings;

const FormGroup = styled.div`
    padding-bottom: 20px !important;

    .react-select__control {
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
`;

ProfileSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
