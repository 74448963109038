import { Status } from '../constants/commonConstnt';

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const emailPatter = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
export const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

export const feeTypes = [{
  value: 'discount',
  label: 'Discount'
}, {
  value: 'late_fee',
  label: 'Late Fee'
}];
export const valueTypes = [{
  value: 'fixed',
  label: 'Fixed Value'
}, {
  value: 'percentage',
  label: 'Percentage'
}];
export const percentageTypes = [{
  value: 'on_total_rent',
  label: '% of total rent'
}, {
  value: 'on_total_amount_over_due',
  label: '% of total amount over due'
}, {
  value: 'on_utility',
  label: '% of utility'
}];
export const frequencies = [{
  value: 'one_time',
  label: 'One Time'
}, {
  value: 'daily',
  label: 'Daily'
}, {
  value: 'monthly',
  label: 'Monthly'
}, {
  value: 'yearly',
  label: 'Yearly'
}];
export const adjustmentTypes = [{
  value: 'add',
  label: 'Add'
}, {
  value: 'deduct',
  label: 'Deduct'
}, {
  value: 'advance_deduct',
  label: 'Advance Deduct'
}];
export const invoiceStatus = [
  {
    value: 'Open',
    label: 'Open'
  }, {
    value: 'Sent',
    label: 'Sent'
  }, {
    value: 'Paid',
    label: 'Paid'
  }, {
    value: 'Partially Paid',
    label: 'Partially Paid'
  }, {
    value: 'Cancelled',
    label: 'Cancelled'
  }
];

const getTooltipStyles = (themeName, type) => {
  switch (themeName) {
    case 'dark': {
      const {
        backgroundColor,
        color
      } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'light': {
      return ligthTheme;
    }
    default:
      return ligthTheme;
  }
};

export default getTooltipStyles;

export const firstLetterToUpperCase = (str) => {
  if (!str) return 'Something went wrong';

  const firstLetterUpperCase = str[0].toUpperCase();
  return `${firstLetterUpperCase}${str.slice(1)}`;
};

export const getKeyByValue = (object, value) => Object.keys(object)
  .find(key => object[key] === value);

export const ucFirst = str => str[0].toUpperCase() + str.slice(1);

export const generateFormInitialValue = (rawData, convertSelectDefault = [], mapValue = []) => {
  const data = [];
  Object.keys(rawData)
    .forEach((key) => {
      if (rawData[key] != null) {
        data[key] = rawData[key];
        if (key === 'status') {
          data[key] = {
            value: parseInt(rawData[key]),
            label: Status[rawData[key]]
          };
        }
        if (Object.values(convertSelectDefault)
          .indexOf(key) > -1) {
          if (rawData[getKeyByValue(convertSelectDefault, key)]) {
            data[key] = {
              value: rawData[key],
              label: rawData[getKeyByValue(convertSelectDefault, key)][mapValue[getKeyByValue(convertSelectDefault, key)]],
            };
          }
        }
      }
    });
  Object.keys(data)
    .forEach((key) => {
      if (getKeyByValue(convertSelectDefault, key) !== undefined) {
        delete data[getKeyByValue(convertSelectDefault, key)];
      }
    });
  return data ? { ...data } : null;
};

export const range = (start, end, step) => {
  const range = [];
  const typeofStart = typeof start;
  const typeofEnd = typeof end;

  if (step === 0) {
    throw TypeError('Step cannot be zero.');
  }

  if (typeofStart === 'undefined' || typeofEnd === 'undefined') {
    throw TypeError('Must pass start and end arguments.');
  } else if (typeofStart !== typeofEnd) {
    throw TypeError('Start and end arguments must be of same type.');
  }

  typeof step === 'undefined' && (step = 1);

  if (end < start) {
    step = -step;
  }

  if (typeofStart == 'number') {
    while (step > 0 ? end >= start : end <= start) {
      range.push({
        value: start.toString(),
        label: start.toString(),
      });
      start += step;
    }
  } else if (typeofStart == 'string') {
    if (start.length != 1 || end.length != 1) {
      throw TypeError('Only strings with one character are supported.');
    }

    start = start.charCodeAt(0);
    end = end.charCodeAt(0);

    while (step > 0 ? end >= start : end <= start) {
      range.push(String.fromCharCode({
        value: start.toString(),
        label: start.toString(),
      }));
      start += step;
    }
  } else {
    throw TypeError('Only string and number types are supported');
  }

  return range;
};

export const replaceAll = (str, mapObj) => {
  if (str === undefined) return '';

  const re = new RegExp(Object.keys(mapObj)
    .join('|'), 'gi');
  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const prepareData = (data, convertIntKeys, excludes = []) => {
  const formData = new FormData();
  let convertData = [];
  Object.keys(data)
    .forEach(function (key) {
      if (!excludes.includes(key)) {
        if (typeof (data[key]) === 'object' && (data[key]) !== null) {
          convertData[key] = data[key].value;
          if (convertData[key] === undefined) {
            convertData[key] = data[key];
          }
          if (convertIntKeys.includes(key)) {
            convertData[key] = parseInt(data[key].value);
            if (2 === convertData[key] && 'status' === key) {
              convertData[key] = 0;
            }
          }
          if (data[key]['file'] !== undefined) {
            convertData[key] = data[key].file;
          }
        } else {
          convertData[key] = data[key];
          if (convertIntKeys.includes(key)) {
            convertData[key] = parseInt(data[key]);
          }
        }
      }
      formData.append(key, convertData[key]);
    });
  return {
    data: convertData,
    form_data: formData
  };
};

export const encodeDataToURL = (data) => {
  return Object
    .keys(data)
    .map(value => `${value}=${encodeURIComponent(data[value])}`)
    .join('&');
};

export const rand = (min, max = 1000) => {
  return Math.floor(Math.random()
    * (max - min + 1)) + min;
};

Date.prototype.format = function (format) //author: meizz
{
  var o = {
    'M+': this.getMonth() + 1, //month
    'd+': this.getDate(),    //day
    'h+': this.getHours(),   //hour
    'm+': this.getMinutes(), //minute
    's+': this.getSeconds(), //second
    'q+': Math.floor((this.getMonth() + 3) / 3),  //quarter
    'S': this.getMilliseconds() //millisecond
  };

  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1,
        RegExp.$1.length == 1 ? o[k] :
          ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
};

export const dateDiffInDays = (dateTimeStamp1, dateTimeStamp2) => {
  return Math.floor(
    (dateTimeStamp1 - dateTimeStamp2) /
    (1000 * 60 * 60 * 24)
  );
};

export const getTotalCalculationData = (rentAmount = 0, invoiceDetailAmounts = null, adjustments = null, lateDisFees = null, old_due_amount = 0) => {

  let oldAmt = old_due_amount ? parseFloat(old_due_amount) : 0;
  let utilities = invoiceDetailAmounts ? invoiceDetailAmounts : totalAmount;
  let totalUtilities = utilities.length > 1
    ? utilities.reduce((a, b) => (parseFloat(a) + parseFloat(b)))
    : (typeof utilities === 'object' ? parseFloat(utilities[0]) : utilities);
  // totalUtilities = (totalUtilities && totalUtilities.length > 0) ? parseFloat(totalUtilities) : 0;
  const total = isNaN(totalUtilities) ? 0 : totalUtilities;

  let subTotal = (total + parseFloat(rentAmount));

  let gTotal = (total + parseFloat(rentAmount) + oldAmt);
  if (adjustments && adjustments.length > 0) {
    for (const j in adjustments) {
      if ('add' === adjustments[j].adjustment_type) {
        gTotal += adjustments[j].adjustment_amount ? parseFloat(adjustments[j].adjustment_amount) : 0;
      }
      //if ('deduct' === adjustments[j].adjustment_type) {
      if ((adjustments[j].adjustment_type).includes('deduct')) {
        gTotal -= adjustments[j].adjustment_amount ? parseFloat(adjustments[j].adjustment_amount) : 0;
      }
    }
  }
  if (lateDisFees && lateDisFees.length > 0) {
    for (const fk in lateDisFees) {
      if (lateDisFees[fk].fee_type === 'late_fee') {
        gTotal += lateDisFees[fk].total ? parseFloat(lateDisFees[fk].total) : 0;
      }
      if (lateDisFees[fk].fee_type === 'discount') {
        gTotal -= lateDisFees[fk].total ? parseFloat(lateDisFees[fk].total) : 0;
      }
    }
  }
  gTotal = gTotal ? gTotal.toFixed(2) : '';
  return {
    total: total,
    subTotal: subTotal,
    gTotal: gTotal
  };
};
