import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactTableDnDBody from './ReactTableDnDBody';

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow }) => {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return (
                <td {...cell.getCellProps()} id={row.original.id}>{cell.render('Cell')}</td>
              )
            })}
          </tr>
        );
      })}
    </tbody>
  )
};

const ReactTableEmptyBody = ({ getTableBodyProps }) => {
  return (
    <tbody {...getTableBodyProps}>
      <tr>
        <td>No Data Found.</td>
      </tr>
    </tbody>
  )
};

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData
}) => {
  const theme = useSelector(state => state.theme);
  return (
    page.length > 0 ? (
      withDragAndDrop
        ? (
          <ReactTableDnDBody
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            theme={theme}
          />
        ) : (
          <ReactTableDefaultBody
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
        )
    ) : (
      <>
        <ReactTableEmptyBody
          getTableBodyProps
        />
      </>
    )
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
};

export default ReactTableBody;
