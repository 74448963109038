import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import axios from 'axios';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getAccessToken } from '../../../utils/helpers';
import validate from './validate';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import ConfirmBox from '../../../shared/components/Modal/confirm';
import {
  getUtilityBy, getFees, getInvoice,
  getInvoiceDetail,
  getLeasedPropertyList, getPropertyPrefix,
  getRentByPropertyUnit, getTransactionBy,
  getUnitList, getFacilityList,
} from '../../../shared/helpers/getDatas';
import {
  adjustmentTypes,
  dateDiffInDays, feeTypes,
  generateFormInitialValue, getTotalCalculationData, invoiceStatus, percentageTypes,
  prepareData,
  rand,
  ucFirst, valueTypes
} from '../../../shared/helpers';
import { invoiceSave, invoiceDetailsCreate } from '../../../utils/handleSubmit';
import {
  CHECK_ADVANCE_TRANSACTION,
  GENERATE_INVOICE_LATE_DIS,
  GENERATE_INVOICE_NUMBER,
  INVOICE_DETAIL_FORCE_DELETE_BY_INVOICE_ID,
} from '../../App/Router/api_routs';
import { deleteRequest, get } from '../../../utils/api/base';
import styled from 'styled-components';
import { FormWrap, RepeaterWrap } from '../../../shared/components/CustomStyle';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { TransactionCategory } from '../../../shared/constants/commonConstnt';

const CreateInvoice = () => {
  const token = getAccessToken();
  const { id } = useParams();
  const [initValue, setInitValue] = useState({
    facilities: [{}],
    invoiced_date: (new Date()).format('yyyy/MM/dd'),
    adjustments: []
  });
  const history = useHistory();
  const [showFacilityDeleteConfirmBox, setShowFacilityDeleteConfirmBox] = useState(false);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [property, setProperty] = useState('');
  const [rent, setRent] = useState(0);
  const [tenant, setTenant] = useState('');
  // const [invoicePrefix, setInvoicePrefix] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [userId, setUserId] = useState('');
  const [fieldChange, setFieldChange] = useState('');
  const [currentUnit, setCurrentUnit] = useState([0]);
  const [previousUnit, setPreviousUnit] = useState([]);
  const [changedIndex, setChangedIndex] = useState(0);
  const [totalConsumedUnits, setTotalConsumedUnits] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  //const [lateFeeAmount, setLateFeeAmount] = useState(0);
  //const [discountAmount, setDiscountAmount] = useState(0);
  const [fees, setFees] = useState([]);
  const [oldDueAmount, setOldDueAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [variableCharge, setVariableCharge] = useState([0]);
  const [minimumCharge, setMinimumCharge] = useState([0]);
  const [userInvoiceItems, setUserInvoiceItems] = useState([]);
  const [currentRowData, setCurrentRowData] = useState([]);
  //const [lateFee, setLateFee] = useState([]);
  //const [discount, setDiscount] = useState([]);
  //const [leasedProperty, setLeasedProperty] = useState([]);
  const [lastInvoice, setLastInvoice] = useState([]);
  const [isFixedCharge, setIsFixedCharge] = useState([]);
  const [userAdjustment, setUserAdjustment] = useState([]);

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handlePropertyChange = async (selectedOption, e) => {
    const name = e.name;
    setProperty(selectedOption.value);
    //let prefix = '';
    if (selectedOption.value) {
      //prefix = await getPropertyPrefix(token, selectedOption.value);
      await getUnitList(token, selectedOption.value, 0, units, setUnits);
    }
    //setInvoicePrefix(prevState => prevState !== prefix ? prefix : prevState);
    setInitValue(prevState => ({
      ...prevState,
      property: selectedOption
    }));
  };

  const handleUnitChange = async (selectedOption, e) => {
    const name = e.name;
    if (selectedOption.value) {
      let edit_id = id ? id : null;
      let leaseData = await getRentByPropertyUnit(token, property, selectedOption.value, setRent, setUserId, setTenant);
      let invoiceData = await getInvoice(token, property, selectedOption.value, 1, 'DESC', edit_id, leaseData?.user_id);
      let lInv = [];
      if (invoiceData) {
        lInv = invoiceData[0];
        setLastInvoice(lInv);
      }
      if (leaseData) {
        if (!id) {
          const ldFees = await get(GENERATE_INVOICE_LATE_DIS + `/${leaseData.property.id}/${selectedOption.value}/${leaseData.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          });
          setFees(ldFees.data);
          const invNum = await get(GENERATE_INVOICE_NUMBER + `?prefix=${leaseData.property.invoice_prefix}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          });
          setInvoiceNumber(invNum.data);
        }
        //setLeasedProperty(leaseData);
        if (userInvoiceItems.length <= 0) {
          let params = [];
          params['lease_id'] = leaseData.id;
          let unitFacilities = (await getUtilityBy(token, params)).raw;
          setUserInvoiceItems(unitFacilities);
        }
        /*const lateFeeData = await getFees(token, leaseData.id, 'late_fee', 1);
        setLateFee(lateFeeData[0]);
        const discountData = await getFees(token, leaseData.id, 'discount', 1);
        setDiscount(discountData[0]);*/
        //property_id = null, unit_id = null, user_id = null, invoice_id = null
        if (!id) {
          //let currentUserInvoiceData = await getInvoice(token, property, selectedOption.value, 1, 'DESC', edit_id, leaseData?.user_id);
          let oldDueAmount = await dueAmountCheck(leaseData, lInv);
          oldDueAmount = oldDueAmount > 0 ? oldDueAmount : 0;
          setOldDueAmount(oldDueAmount);
          const checkAdvanceRecord = (await get(CHECK_ADVANCE_TRANSACTION, {
            params: {
              transaction_category: TransactionCategory.advance,
              property_id: leaseData.property.id,
              unit_id: leaseData.unit_id,
              tenant_id: leaseData.user_id,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          })).data;
          if (checkAdvanceRecord.amount) {
            let adjData = [{
              adjustment_type: 'deduct',
              adjustment_amount: checkAdvanceRecord.amount
            }];
            setUserAdjustment(adjData);
            setInitValue(prevState => ({
              ...prevState,
              adjustments: [{
                adjustment_type: adjustmentTypes[2],
                adjustment_description: 'Advance Deduction',
                adjustment_amount: checkAdvanceRecord.amount
              }],
              unit: selectedOption
            }));
          } else {
            setUserAdjustment([]);
            setInitValue(prevState => ({
              ...prevState,
              adjustments: [],
              unit: selectedOption
            }));
          }
        }
      }
    }
  };

  const dueAmountCheck = async (leaseData, prevInvoice) => {
    if (!prevInvoice.length) {
      return leaseData.old_due_amount;
    }
    const params = {
      property_id: leaseData.property_id,
      unit_id: leaseData.unit_id,
      user_id: leaseData.user_id,
      invoice_id: prevInvoice.id
    };
    let latestTransaction = await getTransactionBy(token, params);
    if (latestTransaction && latestTransaction.due_amount) {
      return latestTransaction.due_amount;
    }

    if (!latestTransaction && prevInvoice.length && prevInvoice.status.toLowerCase() === 'sent') {
      let invoiceDetailAmounts = prevInvoice.details.map((item) => parseFloat(item.amount));
      const prevInvoiceData = getTotalCalculationData(prevInvoice.rent, invoiceDetailAmounts, prevInvoice.adjustments, prevInvoice.invoice_late_dis, prevInvoice.old_due_amount);
      return prevInvoiceData.gTotal;
    }
  };

  const handleFacilityChange = async (selectedOption, e) => {
    const name = e.name;
    const index = parseInt((name.split('[')[1].split(']')[0]));
    setChangedIndex(index);
    const currentFacility = selectedOption.value;
    let currentFacilityData = userInvoiceItems.find(function (item) {
      if (item.facility.id === currentFacility) {
        return item;
      }
    });
    let currentData = currentRowData;
    currentData[index] = currentFacilityData;
    setCurrentRowData(currentData);

    let fixed_charge = isFixedCharge;
    if (currentFacilityData && currentFacilityData.fixed_charge) {
      fixed_charge[index] = true;
    } else {
      fixed_charge[index] = false;
    }
    setIsFixedCharge(fixed_charge);

    let prevUnit = previousUnit;
    prevUnit[index] = currentFacilityData ? currentFacilityData.initial_unit : 0;
    if (currentFacilityData && currentFacilityData.length === undefined && lastInvoice && lastInvoice.length === undefined) {
      let lastInvData = lastInvoice.details.find(function (item) {
        if (item.facility.id === currentFacility) {
          return item;
        }
      });
      prevUnit[index] = lastInvData.current_unit;
    }
    setPreviousUnit(prevUnit);

    let minCharge = minimumCharge;
    minCharge[index] = currentFacilityData ? currentFacilityData.minimum_charge : 0;
    setMinimumCharge(minCharge);

    let vCharge = variableCharge;
    vCharge[index] = currentFacilityData ? currentFacilityData.variable_charge : 0;
    setVariableCharge(vCharge);

    totalConsumedUnits[index] = currentUnit[index] ? calculateConsumedUnit(currentUnit[index], previousUnit[index]) : 0;
    setTotalConsumedUnits(totalConsumedUnits);
    amountCalculation(index);
    setFieldChange(rand(3));
  };

  const currentUnitChangeHandle = (e, i) => {
    let cu = currentUnit;
    cu[i] = e.target.value ? parseInt(e.target.value) : 0;
    setCurrentUnit(cu);
    setChangedIndex(i);
    let currentFacilityData = [];
    if (currentRowData && currentRowData.length > 0 && currentRowData[i]) {
      currentFacilityData = userInvoiceItems.find(function (item) {
        if (item.facility_id === currentRowData[i].facility_id) {
          return item;
        }
      });
    }
    let curRowData = currentRowData;
    curRowData[i] = currentFacilityData;
    setCurrentRowData(curRowData);
    let prevUnit = previousUnit;
    prevUnit[i] = currentFacilityData ? currentFacilityData.initial_unit : 0;
    if (currentFacilityData && currentFacilityData.length === undefined && lastInvoice && lastInvoice.length === undefined) {
      let lastInvData = lastInvoice.details.find(function (item) {
        if (item.facility.id === currentFacilityData.facility_id) {
          return item;
        }
      });
      prevUnit[i] = lastInvData.current_unit;
    }
    setPreviousUnit(prevUnit);

    let minCharge = minimumCharge;
    minCharge[i] = currentFacilityData ? currentFacilityData.minimum_charge : 0;
    setMinimumCharge(minCharge);

    if(variableCharge.length < 1){
      let vCharge = variableCharge;
      vCharge[i] = currentFacilityData ? currentFacilityData.variable_charge : 0;
      setVariableCharge(vCharge);
    }

    totalConsumedUnits[i] = currentUnit[i] ? calculateConsumedUnit(currentUnit[i], previousUnit[i]) : 0;
    setTotalConsumedUnits(totalConsumedUnits);
    amountCalculation(i);
    setFieldChange(rand(3));
  };

  const variableChangeHandle = (e, i) => {
    let vc = variableCharge;
    vc[i] = e.target.value ? parseInt(e.target.value) : 0;
    setVariableCharge(vc);
    setChangedIndex(i);
    let currentFacilityData = [];
    if (currentRowData && currentRowData.length > 0 && currentRowData[i]) {
      currentFacilityData = userInvoiceItems.find(function (item) {
        if (item.facility_id === currentRowData[i].facility_id) {
          return item;
        }
      });
    }
    let curRowData = currentRowData;
    curRowData[i] = currentFacilityData;
    setCurrentRowData(curRowData);
    let prevUnit = previousUnit;
    prevUnit[i] = currentFacilityData ? currentFacilityData.initial_unit : 0;
    if (currentFacilityData && currentFacilityData.length === undefined && lastInvoice && lastInvoice.length === undefined) {
      let lastInvData = lastInvoice.details.find(function (item) {
        if (item.facility.id === currentFacilityData.facility_id) {
          return item;
        }
      });
      prevUnit[i] = lastInvData.current_unit;
    }
    setPreviousUnit(prevUnit);

    let minCharge = minimumCharge;
    minCharge[i] = currentFacilityData ? currentFacilityData.minimum_charge : 0;
    setMinimumCharge(minCharge);

    totalConsumedUnits[i] = currentUnit[i] ? calculateConsumedUnit(currentUnit[i], previousUnit[i]) : 0;
    setTotalConsumedUnits(totalConsumedUnits);
    amountCalculation(i);
    setFieldChange(rand(3));
  };

  const handleFacilityDelete = (fields, i, form) => {
    if (fields.value[i].id) {
      setShowFacilityDeleteConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
      delete currentUnit[i];
      delete previousUnit[i];
      delete totalConsumedUnits[i];
      delete totalAmount[i];
      totalCalculation();
    }
  };

  const handleAdjustmentDelete = (fields, i, form) => {
    if (fields.value[i].id) {
      setShowFacilityDeleteConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
      delete currentUnit[i];
      delete previousUnit[i];
      delete totalConsumedUnits[i];
      delete totalAmount[i];
      totalCalculation();
    }
  };

  const handleInvoiceCreateSubmit = async (values, form) => {
    let data = prepareData({ ...values }, [], ['facilities', 'adjustments']).data;
    const facilities = values.facilities;
    const adjustments = values.adjustments;
    const invDate = data.invoiced_date ? new Date(data.invoiced_date) : new Date();
    let invoiceData = {
      rent: rent,
      property_id: data.property,
      unit_id: data.unit,
      user_id: userId,
      invoiced_date: invDate.format('yyyy-MM-dd'),
      old_due_amount: oldDueAmount,
      invoice_number: invoiceNumber,
    };

    if (id) {
      invoiceData.id = parseInt(id);
      let status = data.status;
      status = typeof status === 'object' ? status.value : status;
      invoiceData.status = status;
    }
    let prepareInvoiceDetailData = [];
    for (const i in facilities) {
      let invoiceDetails = {};
      invoiceDetails = prepareData(facilities[i], [], []).data;
      invoiceDetails.current_unit = parseInt(currentUnit[i]);
      invoiceDetails.previous_unit = parseInt(previousUnit[i]);
      invoiceDetails.total_consumed_unit = parseInt(totalConsumedUnits[i]);
      invoiceDetails.minimum_charge = parseFloat(minimumCharge[i]);
      if (variableCharge[i]) {
        invoiceDetails.variable_charge = parseFloat(variableCharge[i]);
      }
      invoiceDetails.amount = parseFloat(totalAmount[i]);
      prepareInvoiceDetailData[i] = { ...invoiceDetails };
    }
    invoiceData.details = prepareInvoiceDetailData;

    let preparedInvoiceAdjustment = [];
    if (adjustments) {
      for (const k in adjustments) {
        let adjustmentDetails = {};
        adjustmentDetails = prepareData(adjustments[k], ['adjustment_amount'], []).data;
        adjustmentDetails.adjustment_amount = userAdjustment[k]?.adjustment_amount ?? 0;
        preparedInvoiceAdjustment[k] = { ...adjustmentDetails };
      }
      invoiceData.adjustments = preparedInvoiceAdjustment;
    }

    await invoiceSave(invoiceData, token)
      .then((result) => {
        if (result.data.status === 201 || result.data.status === 200) {
          show('success', result.data.message);

          if (!id) {
            history.push('list');
          }
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });

  };

  const confirmDeleteFacility = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    fields.remove(i);
    setShowFacilityDeleteConfirmBox(false);
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    if (properties.length < 1) {
      await getLeasedPropertyList(token, source, setProperties);
    }
    if (facilities.length < 1) {
      let facilitiesData = await getFacilityList(token);
      setFacilities(facilitiesData.options);
    }
    if (!id) {
      setInvoiceNumber('001');
    }
    if (id && !userId) {
      let data = await getInvoiceDetail(token, source, id);
      setProperty(data.property_id);
      await getUnitList(token, data.property_id, 0, units, setUnits);
      let invoiceData = await getInvoice(token, data.property_id, data.unit_id, 1, 'DESC', id);
      if (invoiceData) {
        setLastInvoice(invoiceData[0]);
      }
      setOldDueAmount(data.old_due_amount);
      let leaseData = await getRentByPropertyUnit(token, data.property_id, data.unit_id, null, setUserId, setTenant);
      if (leaseData) {
        let params = [];
        params['lease_id'] = leaseData.id;
        let utiFas = (await getUtilityBy(token, params)).raw;
        if (data.details && userInvoiceItems.length <= 0) {
          let fac = [];
          for (let i in data.details) {
            let fa = utiFas.filter(function (item, index) {
              if (item.facility.id === data.details[i].facility.id) {
                delete utiFas[index];
                return item;
              }
            });

            fac[i] = fa[0] ?? {};

            if (fac[i].fixed_charge) {
              let fixed_charge = isFixedCharge;
              fixed_charge[i] = true;
              setIsFixedCharge(fixed_charge);
            }
          }
          setUserInvoiceItems(fac);
          setCurrentRowData(fac);
        }
      }
      setInvoiceData(data, leaseData);
      setFees(data.invoice_late_dis);
    }
    return () => {
      source.cancel();
    };
  }, [userId]);

  useEffect(() => {

  }, [fieldChange]);

  const setInvoiceData = (invData, leaseData = null) => {
    let data = [];
    let invoiceData = invData;
    setInvoiceNumber(invoiceData.invoice_number);
    const convertSelectDefault = {
      property: 'property_id',
      unit: 'unit_id'
    };
    const mapValue = {
      property: 'name',
      unit: 'unit_name',
    };
    let status = invoiceData['status'];
    data = generateFormInitialValue(invoiceData, convertSelectDefault, mapValue);
    data.status = [{
      value: status,
      label: ucFirst(status)
    }];
    data.property = data.property_id;
    data.unit = data.unit_id;
    delete data['property_id'];
    delete data['unit_id'];
    let facilities = [];
    let cu = currentUnit;
    let pu = previousUnit;
    let tcu = totalConsumedUnits;
    let minCharge = minimumCharge;
    let vCharge = variableCharge;
    let ta = totalAmount;
    for (const key in invoiceData.details) {
      const convertSelectDefault = {
        facility: 'facility_id',
        type: 'facility_type'
      };
      const mapValue = {
        facility: 'facility',
      };
      facilities[key] = generateFormInitialValue(data.details[key], convertSelectDefault, mapValue);
      cu[key] = invoiceData.details[key].current_unit;
      setCurrentUnit(cu);
      pu[key] = invoiceData.details[key].previous_unit;
      setPreviousUnit(pu);
      tcu[key] = invoiceData.details[key].total_consumed_unit;
      setTotalConsumedUnits(tcu);
      minCharge[key] = invoiceData.details[key].minimum_charge;
      setMinimumCharge(minCharge);
      vCharge[key] = invoiceData.details[key].variable_charge;
      setVariableCharge(vCharge);
      ta[key] = invoiceData.details[key].amount ? (parseFloat(invoiceData.details[key].amount)).toFixed(2) : 0.00;
      setTotalAmount(ta);
    }
    let invDate = new Date(invoiceData.invoiced_date ? invoiceData.invoiced_date : invoiceData.created_at);
    invDate = invDate.format('yyyy/MM/dd');
    data.invoiced_date = invDate;
    delete data['details'];
    data.facilities = facilities;

    let adjustments = [];
    let adj = [];
    for (const key in invoiceData.adjustments) {
      const {
        adjustment_type,
        adjustment_amount
      } = invoiceData.adjustments[key];
      adj[key] = {
        adjustment_type: adjustment_type,
        adjustment_amount: adjustment_amount
      };
      setUserAdjustment(adj);
      adjustments[key] = generateFormInitialValue(data.adjustments[key], [], []);
      adjustments[key].adjustment_type = {
        value: adjustments[key].adjustment_type,
        label: adjustmentTypes.find(function (item) {
          return item.value === adjustments[key].adjustment_type;
        }).label
      };
    }
    data.adjustments = adjustments;
    setInitValue(data);
    setUserId(data.user_id);
    setFees(data.invoice_late_dis);
    setRent(data.rent);
    totalCalculation(data.rent, invoiceData.old_due_amount, adj, data.invoice_late_dis);
  };

  const calculateConsumedUnit = (cUnit, pUnit) => {
    if (cUnit) {
      return parseInt(cUnit) - (pUnit ? parseInt(pUnit) : 0);
    }
    return 1;
  };

  const calculateAmount = (units, totalConsumed) => {
    const min_charge = units.minimum_charge ? parseFloat(units.minimum_charge) : 0;
    const per_unit = units.per_unit ? parseFloat(units.per_unit) : 1;
    if (units.fixed_charge) {
      return (parseFloat(units.fixed_charge) + min_charge).toFixed(2);
    }
    return (parseFloat(totalConsumed) * per_unit + min_charge).toFixed(2);
  };

  const amountCalculation = (index = null) => {
    let currentIndex = index !== null ? index : changedIndex;
    let filterUserUnits = {
      per_unit: 1,
      minimum_charge: 0,
      fixed_charge: 0
    };
    if (currentRowData[currentIndex] && currentRowData[currentIndex] !== undefined) {
      const userFacilityRow = currentRowData[currentIndex];
      filterUserUnits = {
        per_unit: userFacilityRow.variable_charge,
        minimum_charge: userFacilityRow.minimum_charge,
        fixed_charge: userFacilityRow.fixed_charge
      };
    }
    filterUserUnits.per_unit = variableCharge[currentIndex] ?? filterUserUnits.per_unit;
    totalAmount[currentIndex] = calculateAmount(filterUserUnits, totalConsumedUnits[currentIndex]);
    setTotalAmount(totalAmount);
    totalCalculation();
  };

  const totalCalculation = (r = 0, old_due_amount = 0, adj = null, lateDisFees = null) => {
    let rn = r ? r : rent;
    let oldAmt = old_due_amount ? old_due_amount : oldDueAmount;
    const uAdj = adj ? adj : userAdjustment;
    const ldf = lateDisFees ? lateDisFees : fees;
    const calcFata = getTotalCalculationData(rn, totalAmount, uAdj, ldf, oldAmt);
    setTotal(calcFata.total);
    setSubTotal(calcFata.subTotal);
    setGrandTotal(calcFata.gTotal);
  };

  const handleOldDueAmountChange = (e) => {
    setOldDueAmount(e.target.value);
  };

  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleAdjustmentTypeChange = (selectedOption, e) => {
    const name = e.name;
    const index = parseInt((name.split('[')[1].split(']')[0]));
    let at = userAdjustment;
    if (at[index]?.adjustment_type === undefined) {
      at[index] = {
        adjustment_type: selectedOption.value,
        adjustment_amount: 0
      };
    } else {
      at[index].adjustment_type = selectedOption.value;
    }
    setUserAdjustment(at);
    totalCalculation();
  };

  const handleAdjustmentAmountChange = (e, i) => {
    let aj = userAdjustment;
    aj[i].adjustment_amount = e.target.value ? e.target.value : 0;
    setUserAdjustment(aj);
    setFieldChange(rand(5));
    totalCalculation();
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Invoice Create</h3>
          {/* <h3 className="page-subhead subhead">Use these elements, if you want to show some hints or additional
                        information
                    </h3> */}
        </Col>
      </Row>
      <Row>
        <Col md={11} lg={11}>
          <div className="form-wrap" data-token={token}>
            <FormWrap>
              <Form
                onSubmit={(values, e) => handleInvoiceCreateSubmit(values, e)}
                initialValues={initValue}
                mutators={{ ...arrayMutators }}
                validate={validate}
              >
                {({
                  handleSubmit,
                  form,
                  errors,
                  reset,
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <Card id="info-card">
                        <CardBody>
                          <CardTitleWrap>
                            <CardTitle>Invoice</CardTitle>
                            {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                          </CardTitleWrap>
                          {showFacilityDeleteConfirmBox && (
                            <Row>
                              <ConfirmBox
                                color="primary"
                                message="Are you sure you want to delete?"
                                showHide={showFacilityDeleteConfirmBox}
                                onChangeShowHide={setShowFacilityDeleteConfirmBox}
                                callbackConfirm={confirmDeleteFacility}/>
                            </Row>
                          )}
                          <Row>
                            <Col md={6}>
                              <Row>
                                <Col xl={8}>
                                  <FormGroup className="horizontal">
                                    <FormGroupLabel>Property</FormGroupLabel>
                                    <FormGroupField>
                                      <Field
                                        name="property"
                                        component={renderSelectField}
                                        options={properties}
                                        onSelectChange={handlePropertyChange}
                                        placeholder="Select Property"
                                      />
                                    </FormGroupField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={6}>
                              <Row style={{ justifyContent: 'end' }}>
                                <Col xl={6}>
                                  <FormGroup className="horizontal">
                                    <FormGroupLabel>Invoiced Date</FormGroupLabel>
                                    <FormGroupField>
                                      <Field
                                        name="invoiced_date"
                                        component={renderDatePickerField}
                                        placeholder="Invoiced Date"
                                        autoComplete="off"
                                      />
                                      <FormGroupIcon>
                                        <CalendarBlankIcon/>
                                      </FormGroupIcon>
                                    </FormGroupField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl={4}>
                              <FormGroup className="horizontal">
                                <FormGroupLabel>Unit</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="unit"
                                    component={renderSelectField}
                                    options={units[0]}
                                    onSelectChange={handleUnitChange}
                                    placeholder="Select Unit"
                                  />
                                </FormGroupField>
                              </FormGroup>
                              <FormGroup className="horizontal">
                                <FormGroupLabel>Tenant</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="tenant"
                                    component={FormField}
                                    type="number"
                                    //onChange={(e)=>setRent(e.target.value)}
                                    input={{
                                      name: 'tenant',
                                      value: tenant
                                    }}
                                    placeholder="Tenant"
                                    readOnly="readonly"
                                  />
                                </FormGroupField>
                              </FormGroup>
                              <FormGroup className="horizontal">
                                <FormGroupLabel>Rent</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="rent"
                                    component={FormField}
                                    type="number"
                                    input={{
                                      name: 'rent',
                                      value: rent
                                    }}
                                    placeholder="Rent"
                                    readOnly="readonly"
                                  />
                                </FormGroupField>
                              </FormGroup>
                              <FormGroup className="horizontal">
                                <FormGroupLabel>Invoice Number</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="invoice_number"
                                    component={FormField}
                                    type="number"
                                    onChange={(e) => handleInvoiceNumberChange(e)}
                                    input={{
                                      name: 'invoice_number',
                                      value: invoiceNumber
                                    }}
                                    placeholder="Invoice Number"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <Col md={12}>
                              <FormGroup>
                                {/*{showPropertyDeleteConfirmBox && (
                                <Row>
                                  <ConfirmBox
                                    color="primary"
                                    message="Are you sure you want to delete?"
                                    showHide={showPropertyDeleteConfirmBox}
                                    onChangeShowHide={setShowPropertyDeleteConfirmBox}
                                    callbackConfirm={confirmDeleteProperty}/>
                                </Row>
                              )}*/}
                                <RepeaterWrap className="repeater-wrap">
                                  <FieldArray name="facilities">
                                    {({ fields }) => (
                                      <>
                                        {
                                          fields.map((name, i) => (
                                            <InvoiceRow key={i}>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Utility</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.facility_id`}
                                                      component={renderSelectField}
                                                      options={facilities}
                                                      onSelectChange={handleFacilityChange}
                                                      placeholder="Select Facility"
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Current Unit</FormGroupLabel>
                                                  <FormGroupField>
                                                    {isFixedCharge[i]
                                                      ? <Field
                                                        name={`${name}.current_unit`}
                                                        type="number"
                                                        component={FormField}
                                                        input={{
                                                          name: `${name}.current_unit`,
                                                          value: currentUnit[i] ?? 0
                                                        }}
                                                        placeholder="Current Unit"
                                                        readOnly="readonly"
                                                      />
                                                      : <Field
                                                        name={`${name}.current_unit`}
                                                        type="number"
                                                        component={FormField}
                                                        onChange={(e) => {
                                                          currentUnitChangeHandle(e, i);
                                                        }}
                                                        input={{
                                                          name: `${name}.current_unit`,
                                                          value: currentUnit[i] ?? 0
                                                        }}
                                                        placeholder="Current Unit"
                                                      />
                                                    }
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Previous Unit</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.previous_unit`}
                                                      component={FormField}
                                                      type="number"
                                                      /*onChange={(e) =>{
                                                        previousUnit[i] = e.target.value;
                                                        setPreviousUnit(previousUnit);
                                                        setFieldChange(e.target.value);
                                                        setChangedIndex(i);
                                                      }}*/
                                                      input={{
                                                        name: `${name}.previous_unit`,
                                                        value: previousUnit[i] ?? 0
                                                      }}
                                                      placeholder="Previous Unit"
                                                      readOnly="readonly"
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Total Consume</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.total_consumed_unit`}
                                                      component={FormField}
                                                      placeholder="Total Consume Unit"
                                                      type="number"
                                                      readOnly="readonly"
                                                      input={{
                                                        name: `${name}.total_consumed_unit`,
                                                        value: totalConsumedUnits[i] ?? 0
                                                      }}
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Variable Charge</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.variable_charge`}
                                                      component={FormField}
                                                      placeholder="Variable Charge"
                                                      type="number"
                                                      onChange={(e) => {
                                                        variableChangeHandle(e, i);
                                                      }}
                                                      input={{
                                                        name: `${name}.variable_charge`,
                                                        value: variableCharge[i] ?? 0
                                                      }}
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Minimum Charge</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.minimum_charge`}
                                                      component={FormField}
                                                      placeholder="Minimum Charge"
                                                      type="number"
                                                      readOnly="readonly"
                                                      input={{
                                                        name: `${name}.minimum_charge`,
                                                        value: minimumCharge[i] ?? 0
                                                      }}
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                              <InvoiceCol>
                                                <FormGroup>
                                                  <FormGroupLabel>Amount</FormGroupLabel>
                                                  <FormGroupField className="align-center">
                                                    <Field
                                                      name={`${name}.amount`}
                                                      component={FormField}
                                                      type="text"
                                                      placeholder="Amount"
                                                      readOnly="readonly"
                                                      input={{
                                                        name: `${name}.amount`,
                                                        value: totalAmount[i] ?? 0
                                                      }}
                                                    />
                                                    {
                                                      (i != 0)
                                                        ? (
                                                          <Link href={void (0)}
                                                                onClick={() => handleFacilityDelete(fields, i, form)}>
                                                            <CloseCircleOutlineIcon/>
                                                          </Link>
                                                        ) : ''
                                                    }
                                                  </FormGroupField>
                                                </FormGroup>
                                              </InvoiceCol>
                                            </InvoiceRow>
                                          ))
                                        }
                                        <FormHalfContainer>
                                          <Button
                                            size="sm"
                                            variant="success"
                                            onClick={() => fields.push({})}
                                          >
                                            <PlusCircleOutlineIcon/> <span>Add Utility</span>
                                          </Button>
                                          {/*{propertyError ? <Error error={propertyError}/> : ''}*/}
                                        </FormHalfContainer>
                                      </>
                                    )}
                                  </FieldArray>
                                </RepeaterWrap>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormGroup>
                                <RepeaterWrap className="repeater-wrap">
                                  <FieldArray name="adjustments">
                                    {({ fields }) => (
                                      <>
                                        {
                                          fields.map((name, i) => (
                                            <Row key={i}>
                                              <Col md={3}>
                                                <FormGroup>
                                                  <FormGroupLabel>Adjustment Type</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.adjustment_type`}
                                                      component={renderSelectField}
                                                      options={adjustmentTypes}
                                                      onSelectChange={handleAdjustmentTypeChange}
                                                      placeholder="Select Adjustment Type"
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </Col>
                                              <Col md={4}>
                                                <FormGroup>
                                                  <FormGroupLabel>Adjustment Description</FormGroupLabel>
                                                  <FormGroupField>
                                                    <Field
                                                      name={`${name}.adjustment_description`}
                                                      component={FormField}
                                                      placeholder="Adjustment Description"
                                                    />
                                                  </FormGroupField>
                                                </FormGroup>
                                              </Col>
                                              <Col md={2}>
                                                <FormGroup>
                                                  <FormGroupLabel>Adjustment Amount</FormGroupLabel>
                                                  <FormGroupField className="align-center">
                                                    <Field
                                                      name={`${name}.adjustment_amount`}
                                                      component={FormField}
                                                      type="text"
                                                      onChange={(e) => {
                                                        handleAdjustmentAmountChange(e, i);
                                                      }}
                                                      input={{
                                                        name: `${name}.adjustment_amount`,
                                                        value: userAdjustment[i]?.adjustment_amount ?? 0
                                                      }}
                                                      placeholder="Adjustment Amount"
                                                    />
                                                    {
                                                      (i != 0)
                                                        ? (
                                                          <Link href={void (0)}
                                                                onClick={() => handleAdjustmentDelete(fields, i, form)}>
                                                            <CloseCircleOutlineIcon/>
                                                          </Link>
                                                        ) : ''
                                                    }
                                                  </FormGroupField>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          ))
                                        }
                                        <FormHalfContainer>
                                          <Button
                                            size="sm"
                                            variant="success"
                                            onClick={() => fields.push({})}
                                          >
                                            <PlusCircleOutlineIcon/> <span>Add Adjustment</span>
                                          </Button>
                                          {/*{propertyError ? <Error error={propertyError}/> : ''}*/}
                                        </FormHalfContainer>
                                      </>
                                    )}
                                  </FieldArray>
                                </RepeaterWrap>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="justify-content-end">
                            <Col xl={4}>
                              <InvoiceCalcWrap>
                                <FormGroup className="horizontal">
                                  <FormGroupLabel>Utilities Total: </FormGroupLabel>
                                  <FormGroupField>
                                    <Field
                                      name="total"
                                      component={FormField}
                                      type="number"
                                      placeholder="Total"
                                      readOnly="readonly"
                                      input={{ value: total }}
                                    />
                                  </FormGroupField>
                                </FormGroup>
                                <FormGroup className="horizontal">
                                  <FormGroupLabel>Sub Total: </FormGroupLabel>
                                  <FormGroupField>
                                    <Field
                                      name="total"
                                      component={FormField}
                                      type="number"
                                      placeholder="Total"
                                      readOnly="readonly"
                                      input={{ value: subTotal }}
                                    />
                                  </FormGroupField>
                                </FormGroup>
                                {userAdjustment.map((item, i) => (
                                  <FormGroup className="horizontal" key={i}>
                                    <FormGroupLabel>Adjustment({adjustmentTypes.find(function (adj) {
                                      return adj.value === item.adjustment_type;
                                    })?.label}): </FormGroupLabel>
                                    <FormGroupField>
                                      <Field
                                        name="adjustment_fee"
                                        component={FormField}
                                        type="number"
                                        placeholder="0"
                                        readOnly="readonly"
                                        input={{ value: item.adjustment_amount }}
                                      />
                                    </FormGroupField>
                                  </FormGroup>
                                ))}
                                {fees && fees.length > 0 && fees.map((item, i) => {
                                  let feeTypeLabel = valueTypes.find(function (vt) {
                                    return vt.value === item.fee_value_type;
                                  })?.label;
                                  if (item.fee_value_type === 'percentage') {
                                    feeTypeLabel = percentageTypes.find(pt => pt.value === item.percentage_type).label;
                                    if (item.percentage_type === 'on_utility') {
                                      feeTypeLabel = item.facility;
                                    }
                                  }
                                  return (
                                    <FormGroup className="horizontal" key={i}>
                                      <FormGroupLabel>{feeTypes.find(function (fee) {
                                        return fee.value === item?.fee_type;
                                      }).label}({feeTypeLabel}): </FormGroupLabel>
                                      <FormGroupField>
                                        <Field
                                          name={item.fee_type}
                                          component={FormField}
                                          type="number"
                                          placeholder="0"
                                          readOnly="readonly"
                                          input={{ value: item.total }}
                                        />
                                      </FormGroupField>
                                    </FormGroup>
                                  );
                                })}
                                <FormGroup className="horizontal">
                                  <FormGroupLabel>Old Due Amount: </FormGroupLabel>
                                  <FormGroupField>
                                    <Field
                                      name="old_due_amount"
                                      component={FormField}
                                      type="number"
                                      placeholder="0"
                                      input={{ value: oldDueAmount }}
                                      onChange={handleOldDueAmountChange}
                                    />
                                  </FormGroupField>
                                </FormGroup>
                                <FormGroup className="horizontal">
                                  <FormGroupLabel>Grand Total: </FormGroupLabel>
                                  <FormGroupField>
                                    <Field
                                      name="grand_total"
                                      component={FormField}
                                      type="number"
                                      readOnly="readonly"
                                      placeholder="Grand Total"
                                      input={{ value: grandTotal }}
                                    />
                                  </FormGroupField>
                                </FormGroup>
                                {id && <FormGroup className="horizontal">
                                  <FormGroupLabel>Payment Status: </FormGroupLabel>
                                  {initValue.status && initValue.status[0] && !(['Paid', 'Cancelled'].includes(initValue.status[0].value)) ?
                                    <FormGroupField>
                                      <Field
                                        name="status"
                                        component={renderSelectField}
                                        options={invoiceStatus}
                                        placeholder="Select status"
                                      />
                                    </FormGroupField>
                                    : <FormGroupField>
                                      <input readOnly="readonly" value="Paid"
                                             style={{ backgroundColor: '#f6f6f6' }}/>
                                    </FormGroupField>
                                  }
                                </FormGroup>}
                              </InvoiceCalcWrap>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      {(!id || (id && initValue.status && initValue.status[0] && !(['Paid', 'Cancelled'].includes(initValue.status[0].value)))) &&
                        <FormButtonToolbar>
                          <Button variant="primary" type="submit">Submit</Button>
                          <Button variant="secondary" type="button" onClick={reset}>
                            Cancel
                          </Button>
                        </FormButtonToolbar>
                      }
                    </FormContainer>
                  );
                }}
              </Form>
            </FormWrap>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CreateInvoice;

const InvoiceRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InvoiceCol = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 5px;

    &:last-child {
        padding-right: 0;
    }

    @media screen and (min-width: 1200px) {
        width: 13%;
    }
`;

const InvoiceCalcWrap = styled.div`
    display: block;

    .horizontal {
        span {
            min-width: 150px;
        }
    }
`;
