import { React, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { roleSubmit } from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField, FormGroupIcon,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';

const AddRole = () => {

    const token = getAccessToken();

    const { rtl, theme } = useSelector(state => ({
        rtl: state.rtl,
        theme: state.theme,
    }));

    const show = (color, message) => useNotification({
      notification() {
        return (
          <BasicNotification
            color={color}
            title="Role"
            message={message}
            theme={theme}
          />
        );
      },
      position: 'right-up'
    });

    const handleRoleSubmit = (values, form) => {
        roleSubmit(values, token).then((result) => {
            if (result.data.status == 201) {
                show('success', result.data.message);
                form.reset();
                for (let key in values){
                    form.resetFieldState(key);
                }
            } else {
                show('danger', result.data.errors.join('<br/>'));
            }
        })
    }

    useEffect(() => {

    }, [])

    return (
        <Container >
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Role Add Form</h3>
                    {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                        information
                    </h3> */}
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>
                                <CardTitle>Add Role</CardTitle>
                                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                            </CardTitleWrap>
                            <Form onSubmit={(values, e) => handleRoleSubmit(values, e)} validate={validate}>
                                {({ handleSubmit, form }) => {
                                    return (
                                        <FormContainer onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <FormGroupLabel>Role Name</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="name"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Role"
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                            <FormButtonToolbar>
                                                <Button variant="primary" type="submit">Submit</Button>
                                                <Button variant="secondary" type="button" onClick={form.reset}>
                                                    Cancel
                                                </Button>
                                            </FormButtonToolbar>
                                        </FormContainer>
                                    )
                                }}
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}


export default AddRole;
