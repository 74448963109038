import styled from 'styled-components';

export const DownloadButtons = styled.div`
    width: 100%;

    a {
        margin-right: 10px;
    }

    a:last-child {
        margin-right: 0;
    }

    a:hover {
        color: #0080ff;
    }
`;
