import store from '../../../../containers/App/store';
import { auth, authError, authLogout } from '../../../../redux/actions/authActions';
import { post } from '../../../../utils/api/base';
import { LOGOUT_USER } from '../../../../containers/App/Router/api_routs';

export const initAuth = () => {
    const userAccess = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
    if (userAccess) {
        const fullName = userAccess?.name;
        const userData = userAccess?.userData;
        store.dispatch(auth({
            name: fullName,
            avatar: '',
            accessToken: userAccess?.accessToken,
            userData,
        }));
    }
};

export const login = (user) => {
    if (user?.data?.status === 401) {
        store.dispatch(authError(user?.data?.message));
    } else {
        const fullName = user?.data?.user?.name;
        const userData = user?.data?.user;
        store.dispatch(auth({
            name: fullName,
            avatar: '',
            accessToken: user?.data?.token,
            userData,
        }));
        localStorage.setItem('pmf', JSON.stringify({
            name: fullName,
            avatar: '',
            accessToken: user?.data?.token,
            userData,
        }));
        window.location.href = '/dashboard';
    }
};

export const logout = async (user) => {
    localStorage.removeItem('pmf');
    const userAccess = user?.accessToken;
    const result = post(LOGOUT_USER, {}, {
        headers: { Authorization: `Bearer ${userAccess}`, Accept: 'application/json' },
    });
    result.then(() => {
        store.dispatch(authLogout({}));
    }).catch((e) => {
        store.dispatch(authError(e));
    });
};
