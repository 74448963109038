import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import {UserRoles} from "../../../../shared/constants/userroles";
import addPaymentMethod from '../../../PaymentMethod/Components/add';
import { PaymentMethod } from '../../../PaymentMethod';

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/payment-method/list" component={PaymentMethod}/>
            <Route path="/payment-method/add" component={addPaymentMethod}/>
            <Route path="/payment-method/edit/:id" component={addPaymentMethod}/>
        </PermissionRoute>
    </Switch>
);
