/* eslint-disable */

import {sleep} from "@amcharts/amcharts5/.internal/core/util/Time";

const validate = async (values) => {
    await sleep(100);
    const errors = {};
    if (!values.name) {
        errors.name = 'Name field is required.';
    }
    if (!values.address1) {
        errors.address1 = 'Address line 1 field is required.';
    }
    if (!values.country_id) {
        errors.country_id = 'Country field is required.';
    }
    if (!values.state_id) {
        errors.state_id = 'State field is required.';
    }
    if (!values.city) {
        errors.city = 'City field is required.';
    }
    if (!values.property_type_id) {
        errors.property_type_id = 'Property type field is required.';
    }
    if (!values.property_built_year) {
        errors.property_built_year = 'Property build year field is required.';
    }
    if (!values.total_floors) {
        errors.total_floors = 'Total floors field is required.';
    }
    if (!values.property_total_area) {
        errors.property_total_area = 'Area field is required.';
    }
    if (!values.status) {
        errors.status = 'Status field is required.';
    }

    return errors;
};

export default validate;
