import { React, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  documentSave,
  feeSave,
  paymentSettingsSave,
  propertyLeaseSave,
  utilitySave
} from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
  FormHalfWrap,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteRequest, get } from '../../../utils/api/base';
import {
  DELETE_DOCUMENTS,
  DELETE_FEES,
  DELETE_PAYMENT_SETTINGS,
  DELETE_UTILITY,
  FACILITIES,
  LEASE_UNITS
} from '../../App/Router/api_routs';
import {
  feeTypes, frequencies,
  generateFormInitialValue, percentageTypes,
  prepareData,
  range,
  replaceAll,
  ucFirst,
  valueTypes
} from '../../../shared/helpers';
import axios from 'axios';
import {
  getDocumentTypeList,
  getPropertyList,
  getUnitList,
  getUsers, paymentMethods,
  utilities,
} from '../../../shared/helpers/getDatas';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { UserRoles } from '../../../shared/constants/userroles';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import Error from '../../../shared/components/form/Error';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FormHalfContainer } from '../../../shared/components/form/FormElements';
import { Link } from '@material-ui/core';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import ConfirmBox from '../../../shared/components/Modal/confirm';
import renderFileInputField from '@/shared/components/form/FileInput';
import { FormWrap, RepeaterWrap } from '@/shared/components/CustomStyle';

const AddLease = () => {

  const token = getAccessToken();
  const { id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState([]);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [depositType, setDepositType] = useState('cash');
  const [feeValueType, setFeeValueType] = useState(['fixed']);
  const [percentageType, setPercentageType] = useState([]);
  const [utilityData, setUtilityData] = useState([]);
  const [showLateFeeDiscountDeleteConfirmBox, setShowLateFeeDiscountConfirmBox] = useState(false);
  const [showUtilityDeleteConfirmBox, setShowUtilityConfirmBox] = useState(false);
  const [showPaymentMethodDeleteConfirmBox, setShowPaymentMethodConfirmBox] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [showDocumentDeleteConfirmBox, setShowDocumentDeleteConfirmBox] = useState(false);
  const [paymentMethodData, setPaymentMethodData] = useState([]);

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Utilities"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const handlePropertyChange = async (selectedOption, e) => {
    const name = e.name;
    if (selectedOption.value) {
      await getUnitList(token, selectedOption.value, 0, units, setUnits, false, true);
    }
  };

  const handleDepositTypeChange = (selectedOption) => {
    setDepositType(selectedOption.value);
  };

  const handleFeeValueTypeChange = (selectedOption, fields, i) => {
    let feeVal = feeValueType;
    feeVal[i] = selectedOption.value;
    setFeeValueType(feeVal);
    if (feeVal[i] !== 'percentage') {
      setPercentageType([]);
    }
  };

  const handlePercentageTypeChange = (selectedOption, fields, i) => {
    let perType = percentageType;
    perType[i] = selectedOption.value;
    setPercentageType(perType);
  };

  const handleAddLease = async (values, form) => {
    let leaseData = [];
    let convertIntKeys = ['status', 'amount', 'deposit_amount', 'generate_invoice_on', 'old_due_amount'];
    const excludeKeys = ['lateFeeDiscount', 'utilities', 'paymentSettings', 'documents', 'tenant'];
    const tenant = values.tenant.value;
    const utilitiesData = prepareData(values, convertIntKeys, excludeKeys);
    leaseData = { ...(utilitiesData.data) };
    leaseData.user_id = tenant;
    savePropertyLease(leaseData, tenant, values, form);
  };

  const dateFormat = (d) => {
    return `${d.getFullYear()}/${("0" + (d.getMonth() + 1)).slice(-2)}/${("0" + d.getDate()).slice(-2)}`;
  }
  const savePropertyLease = (data, user_id, values, form) => {
    form = form || null;
    data.user_id = parseInt(user_id);
    const propertyData = prepareData(data, []).data;
    let propData = { ...propertyData };
    let st_date = new Date(propData.start_date);
    let end_date = new Date(propData.end_date);
    propData.start_date = dateFormat(st_date);
    propData.end_date = dateFormat(end_date);
    const {
      lateFeeDiscount,
      utilities,
      paymentSettings,
      documents
    } = values;
    const tenant = values.tenant.value;
    propertyLeaseSave(propData, token)
      .then((result) => {
        if (result.data.status === 201) {
          let property_lease_id = result.data.property_lease.id;
          saveLateFeeDiscount(lateFeeDiscount, tenant, property_lease_id, form);
          saveUtilities(utilities, tenant, property_lease_id, form);
          savePaymentSettings(paymentSettings, tenant, property_lease_id, form);
          if (documents) {
            saveDocument(documents, tenant, property_lease_id, form);
          }
          show('success', result.data.message);
          if (form && !id) {
            history.push('list');
            /*form.reset(values);
            for (const key in propData) {
                form.resetFieldState(key);
            }*/
          }
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });
  };

  const saveLateFeeDiscount = (fees, user_id, property_lease_id, form) => {
    form = form || null;
    fees.forEach(function (fee) {
      fee.user_id = parseInt(user_id);
      fee.property_lease_id = parseInt(property_lease_id);
      const feeData = prepareData(fee, ['fee_value', 'grace_period']);
      const LFDData = { ...(feeData.data) };
      feeSave(LFDData, token)
        .then((result) => {
          if (result.data.status === 201) {
            //show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const saveUtilities = (utilities, user_id, property_lease_id, form) => {
    form = form || null;
    utilities.forEach(function (utility, i) {
      utility.user_id = parseInt(user_id);
      utility.property_lease_id = parseInt(property_lease_id);
      if (!id) {
        utility.order = parseInt(i) + 1;
      }
      const convertInt = ['initial_unit', 'minimum_charge', 'variable_charge', 'fixed_charge'];
      const prepData = prepareData(utility, convertInt);
      const utilityData = { ...(prepData.data) };
      utilitySave(utilityData, token)
        .then((result) => {
          if (result.data.status === 201) {
            //show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const savePaymentSettings = (paymentSettings, user_id, property_lease_id, form) => {
    form = form || null;
    paymentSettings.forEach(function (paymentSetting) {
      paymentSetting.user_id = parseInt(user_id);
      paymentSetting.property_lease_id = parseInt(property_lease_id);
      const convertInt = ['initial_unit', 'minimum_charge', 'variable_charge', 'fixed_charge'];
      const prepData = prepareData(paymentSetting, convertInt);
      const PSData = { ...(prepData.data) };
      paymentSettingsSave(PSData, token)
        .then((result) => {
          if (result.data.status === 201) {
            //show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const saveDocument = (documents, user_id, property_lease_id, form) => {
    form = form || null;
    documents.forEach(function (document) {
      document.user_id = parseInt(user_id);
      document.property_lease_id = parseInt(property_lease_id);
      const documentData = prepareData(document, ['document_type', 'user_id']);
      const formData = documentData.form_data;
      const docData = { ...(documentData.data) };
      documentSave(formData, token)
        .then((result) => {
          if (result.data.status === 201) {
            //show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const handleLateFeeDiscountDelete = (fields, i) => {
    if (fields.value[i].id) {
      setShowLateFeeDiscountConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
    }
  };
  const confirmDeleteLateFeeDiscount = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    deleteRequest(DELETE_FEES + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowLateFeeDiscountConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e.data.message);
      });
  };

  const handUtilityDelete = (fields, i) => {
    if (fields.value[i].id) {
      setShowUtilityConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
    }
  };
  const confirmDeleteUtility = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    deleteRequest(DELETE_UTILITY + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowUtilityConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.data.message);
      });
  };

  const handPaymentMethodDelete = (fields, i) => {
    if (fields.value[i].id) {
      setShowPaymentMethodConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
    }
  };
  const confirmDeletePaymentMethod = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    fields.remove(i);
    deleteRequest(DELETE_PAYMENT_SETTINGS + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowPaymentMethodConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.data.message);
      });
  };
  const confirmDeleteDocument = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    deleteRequest(DELETE_DOCUMENTS + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowDocumentDeleteConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.data.message);
      });
  };

  const deleteDocument = (fields, i) => {
    if (fields.value[i].id) {
      setShowDocumentDeleteConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
    }
  };

  useEffect(async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    await getPropertyList(token, source, setProperties);
    await getUsers(token, setTenants, '?role=' + UserRoles.TENANT);
    await getDocumentTypeList(token, source, setDocumentTypes);
    let urlParams = `?status=1`;
    const utility = await utilities(token, null, null, null, urlParams);
    if (utility) {
      let options = [];
      for (const k in utility) {
        options[k] = {
          value: utility[k].id,
          label: utility[k].facility,
        };
      }
      setUtilityData(options);
    }
    await paymentMethods(token, setPaymentMethodData);
    if (id) {
      let leasedProperty = await get(LEASE_UNITS + `/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      });
      leasedProperty = leasedProperty ? leasedProperty.data.property_lease : '';
      if (leasedProperty) {
        let convertSelectDefault = {
          user: 'user_id',
          property: 'property_id',
          unit: 'unit_id'
        };
        let mapValue = {
          user: 'name',
          property: 'name',
          unit: 'unit_name',
        };
        let data = generateFormInitialValue(leasedProperty, convertSelectDefault, mapValue);
        data.tenant = data.user_id;
        data.deposit_type = {
          value: data.deposit_type,
          label: ucFirst(data.deposit_type)
        };
        data.due_on = {
          value: data.due_on,
          label: data.due_on
        };
        data.generate_invoice_on = {
          value: data.generate_invoice_on,
          label: data.generate_invoice_on
        };
        delete data['user_id'];

        let lateFeeDiscount = [];
        for (const key in data.fees) {
          lateFeeDiscount[key] = generateFormInitialValue(data.fees[key]);
          lateFeeDiscount[key].fee_type = {
            value: lateFeeDiscount[key].fee_type,
            label: feeTypes.find(function (item) {
              return item.value === lateFeeDiscount[key].fee_type;
            }).label
          };

          let fee_value_type = feeValueType;
          fee_value_type[key] = lateFeeDiscount[key].fee_value_type;
          setFeeValueType(fee_value_type);
          if (fee_value_type[key] !== 'percentage') {
            setPercentageType([]);
          }

          lateFeeDiscount[key].fee_value_type = {
            value: lateFeeDiscount[key].fee_value_type,
            label: valueTypes.find(function (item) {
              return item.value === lateFeeDiscount[key].fee_value_type;
            })?.label
          };

          let percentage_type = percentageType;
          percentage_type[key] = lateFeeDiscount[key].percentage_type;
          setPercentageType(percentage_type);

          lateFeeDiscount[key].percentage_type = {
            value: lateFeeDiscount[key].percentage_type,
            label: percentageTypes.find(function (item) {
              return item.value === lateFeeDiscount[key].percentage_type;
            })?.label
          };

          if (lateFeeDiscount[key].utility_id) {
            lateFeeDiscount[key].utility_id = {
              value: lateFeeDiscount[key].utility_id,
              label: lateFeeDiscount[key].facility.facility
            };
          }

          lateFeeDiscount[key].frequency = {
            value: lateFeeDiscount[key].frequency,
            label: frequencies.find(function (item) {
              return item.value === lateFeeDiscount[key].frequency;
            })?.label
          };
        }
        data.lateFeeDiscount = lateFeeDiscount;
        delete data['fees'];

        for (const i in data.utilities) {
          data.utilities[i] = generateFormInitialValue(data.utilities[i], { facility: 'facility_id' }, { facility: 'facility' });
        }

        let paymentSettings = [];
        for (const k in data.payment_settings) {
          paymentSettings[k] = generateFormInitialValue(data.payment_settings[k], { payment_method: 'payment_method_id' }, { payment_method: 'name' });
        }
        data.paymentSettings = paymentSettings;
        delete data['payment_settings'];

        for (const i in data.documents) {
          data.documents[i] = generateFormInitialValue(data.documents[i], { document_types: 'document_type' }, { document_types: 'document_type' });
        }
        setDepositType(leasedProperty.deposit_type);
        setInitialValues(data);
      }
    }
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Lease</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <FormWrap>
            <Form onSubmit={(values, e) => handleAddLease(values, e)}
                  initialValues={initialValues}
                  mutators={{ ...arrayMutators }}
                  validate={validate}>
              {({
                handleSubmit,
                form,
                errors,
                touched
              }) => {
                const mapObj = {
                  '[': '',
                  ']': '',
                  '{': '',
                  '}': ''
                };
                const lateFeeDiscountError = typeof errors.lateFeeDiscount === 'string' ? replaceAll(JSON.stringify(errors.lateFeeDiscount, 0, 2), mapObj) : '';
                const utilitiesError = typeof errors.utilities === 'string' ? replaceAll(JSON.stringify(errors.utilities, 0, 2), mapObj) : '';
                const paymentSettingsError = typeof errors.paymentSettings === 'string' ? replaceAll(JSON.stringify(errors.paymentSettings, 0, 2), mapObj) : '';
                const documentError = typeof errors.documents === 'string' ? replaceAll(JSON.stringify(errors.documents, 0, 2), mapObj) : '';
                return (
                  <FormContainer onSubmit={handleSubmit}>
                    <Card id="tenant">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Add Lease</CardTitle>
                        </CardTitleWrap>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <FormGroupLabel>Tenant</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="tenant"
                                  component={renderSelectField}
                                  options={tenants}
                                  placeholder="Select Tenant"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card id="lease-info">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Lease Info</CardTitle>
                        </CardTitleWrap>
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Property</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="property_id"
                                  component={renderSelectField}
                                  options={properties}
                                  onSelectChange={handlePropertyChange}
                                  placeholder="Select Property"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Unit</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="unit_id"
                                  component={renderSelectField}
                                  options={units[0]}
                                  //onSelectChange={handlePropertyChange}
                                  placeholder="Select Property"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Old Due Amount</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="old_due_amount"
                                  component={FormField}
                                  type="number"
                                  placeholder="Old Due Amount"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Amount</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="amount"
                                  component={FormField}
                                  type="number"
                                  placeholder="Rent Amount"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Start Date</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="start_date"
                                  component={renderDatePickerField}
                                  placeholder="Start Date"
                                  autoComplete="off"
                                />
                                <FormGroupIcon>
                                  <CalendarBlankIcon/>
                                </FormGroupIcon>
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>End Date</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="end_date"
                                  component={renderDatePickerField}
                                  placeholder="End Date"
                                  autoComplete="off"
                                />
                                <FormGroupIcon>
                                  <CalendarBlankIcon/>
                                </FormGroupIcon>
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          {/*<Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Due On (Day of month)</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="due_on"
                                  component={renderSelectField}
                                  options={range(0, 30, 1)}
                                  placeholder="Select Day of month"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>*/}
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Status</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="status"
                                  component={renderSelectField}
                                  options={[{
                                    value: 1,
                                    label: 'Active'
                                  }, {
                                    value: 2,
                                    label: 'Inactive'
                                  }]}
                                  placeholder="Status"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card id="security-deposit">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Security Deposit</CardTitle>
                        </CardTitleWrap>
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Type</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="deposit_type"
                                  component={renderSelectField}
                                  options={[{
                                    value: 'cash',
                                    label: 'Cash'
                                  }, {
                                    value: 'cheque',
                                    label: 'Cheque'
                                  }]}
                                  onSelectChange={handleDepositTypeChange}
                                  placeholder="Select Type"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={8}>
                            {depositType === 'cash'
                              ?
                              <FormGroup>
                                <FormGroupLabel>Deposit Amount</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="deposit_amount"
                                    component={FormField}
                                    type="number"
                                    placeholder="Deposit Amount"
                                  />
                                </FormGroupField>
                              </FormGroup>
                              :
                              <FormGroup>
                                <FormGroupLabel>Bank Details</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="bank_details"
                                    component="textarea"
                                    type="textarea"
                                    placeholder="Bank Details"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card id="late-fee-discount">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Late Fee / Discount</CardTitle>
                        </CardTitleWrap>
                        {showLateFeeDiscountDeleteConfirmBox && (
                          <Row>
                            <ConfirmBox
                              color="primary"
                              message="Are you sure you want to delete?"
                              showHide={showLateFeeDiscountDeleteConfirmBox}
                              onChangeShowHide={setShowLateFeeDiscountConfirmBox}
                              callbackConfirm={confirmDeleteLateFeeDiscount}/>
                          </Row>
                        )}
                        <RepeaterWrap>
                          <FieldArray name="lateFeeDiscount">
                            {({ fields }) => (
                              <>
                                {
                                  fields.map((name, i) => (
                                    <Row key={i} className="bg-alternate-color">
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Fee Type</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.fee_type`}
                                              component={renderSelectField}
                                              options={feeTypes}
                                              placeholder="Select Type"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Value Type</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.fee_value_type`}
                                              component={renderSelectField}
                                              options={valueTypes}
                                              onSelectChange={(e) => handleFeeValueTypeChange(e, fields, i)}
                                              placeholder="Select Value Type"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      {feeValueType[i] === 'percentage' &&
                                        <Col md={2}>
                                          <FormGroup>
                                            <FormGroupLabel>Percentage Type</FormGroupLabel>
                                            <FormGroupField>
                                              <Field
                                                name={`${name}.percentage_type`}
                                                component={renderSelectField}
                                                options={percentageTypes}
                                                onSelectChange={(e) => handlePercentageTypeChange(e, fields, i)}
                                                placeholder="Select Percentage Type"
                                              />
                                            </FormGroupField>
                                          </FormGroup>
                                        </Col>
                                      }
                                      {percentageType[i] === 'on_utility' &&
                                        <Col md={2}>
                                          <FormGroup>
                                            <FormGroupLabel>Utility</FormGroupLabel>
                                            <FormGroupField>
                                              <Field
                                                name={`${name}.utility_id`}
                                                component={renderSelectField}
                                                options={utilityData}
                                                placeholder="Select Utility"
                                              />
                                            </FormGroupField>
                                          </FormGroup>
                                        </Col>
                                      }
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Value</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.fee_value`}
                                              component={FormField}
                                              type="number"
                                              placeholder="Value"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Grace Period (Days)</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.grace_period`}
                                              component={FormField}
                                              type="text"
                                              placeholder="Grace period"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Frequency</FormGroupLabel>
                                          <FormGroupField className="align-center">
                                            <Field
                                              name={`${name}.frequency`}
                                              component={renderSelectField}
                                              options={frequencies}
                                              placeholder="Select Frequency"
                                            />
                                            {
                                              (i != 0)
                                                ? (
                                                  <Link href={void (0)}
                                                        onClick={() => handleLateFeeDiscountDelete(fields, i, form)}>
                                                    <CloseCircleOutlineIcon/>
                                                  </Link>
                                                ) : ''
                                            }
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  ))
                                }
                                <FormHalfContainer>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => fields.push({})}
                                  >
                                    <PlusCircleOutlineIcon/> <span>Add</span>
                                  </Button>
                                  {lateFeeDiscountError ? <Error error={lateFeeDiscountError}/> : ''}
                                </FormHalfContainer>
                              </>
                            )}
                          </FieldArray>
                        </RepeaterWrap>
                      </CardBody>
                    </Card>
                    <Card id="utilities">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Utilities</CardTitle>
                        </CardTitleWrap>
                        {showUtilityDeleteConfirmBox && (
                          <Row>
                            <ConfirmBox
                              color="primary"
                              message="Are you sure you want to delete?"
                              showHide={showUtilityDeleteConfirmBox}
                              onChangeShowHide={setShowUtilityConfirmBox}
                              callbackConfirm={confirmDeleteUtility}/>
                          </Row>
                        )}
                        <RepeaterWrap>
                          <FieldArray name="utilities">
                            {({ fields }) => (
                              <>
                                {
                                  fields.map((name, i) => (
                                    <Row key={i} className="bg-alternate-color">
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Utility</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.facility_id`}
                                              component={renderSelectField}
                                              options={utilityData}
                                              placeholder="Select Utility"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Initial Unit</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.initial_unit`}
                                              component={FormField}
                                              type="number"
                                              placeholder="Initial Unit"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Minimum Charge</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.minimum_charge`}
                                              component={FormField}
                                              type="number"
                                              placeholder="Minimum Charge"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Variable Charge</FormGroupLabel>
                                          <FormGroupField>
                                            <Field
                                              name={`${name}.variable_charge`}
                                              component={FormField}
                                              type="number"
                                              placeholder="Variable Charge"
                                            />
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <FormGroupLabel>Fixed Charge</FormGroupLabel>
                                          <FormGroupField className="align-center">
                                            <Field
                                              name={`${name}.fixed_charge`}
                                              component={FormField}
                                              type="number"
                                              placeholder="Fixed Charge"
                                            />
                                            {
                                              // (i != 0)
                                              //   ? (
                                                  <Link href={void (0)}
                                                        onClick={() => handUtilityDelete(fields, i, form)}>
                                                    <CloseCircleOutlineIcon/>
                                                  </Link>
                                                //) : ''
                                            }
                                          </FormGroupField>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  ))
                                }
                                <FormHalfContainer>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => fields.push({})}
                                  >
                                    <PlusCircleOutlineIcon/> <span>Add</span>
                                  </Button>
                                  {utilitiesError ? <Error error={utilitiesError}/> : ''}
                                </FormHalfContainer>
                              </>
                            )}
                          </FieldArray>
                        </RepeaterWrap>
                      </CardBody>
                    </Card>
                    <Card id="payment-settings">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Payment Settings</CardTitle>
                        </CardTitleWrap>
                        {showPaymentMethodDeleteConfirmBox && (
                          <Row>
                            <ConfirmBox
                              color="primary"
                              message="Are you sure you want to delete?"
                              showHide={showPaymentMethodDeleteConfirmBox}
                              onChangeShowHide={setShowPaymentMethodConfirmBox}
                              callbackConfirm={confirmDeletePaymentMethod}/>
                          </Row>
                        )}
                        <RepeaterWrap>
                          <FieldArray name="paymentSettings">
                            {({ fields }) => (
                              <Row className="align-center">
                                {
                                  fields.map((name, i) => (
                                    <Col md={2} key={i}>
                                      <FormGroup>
                                        <FormGroupLabel>Payment Method</FormGroupLabel>
                                        <FormGroupField className="align-center">
                                          <Field
                                            name={`${name}.payment_method_id`}
                                            component={renderSelectField}
                                            options={paymentMethodData}
                                            placeholder="Select payment Method"
                                          />
                                          {
                                            (i != 0)
                                              ? (
                                                <Link href={void (0)}
                                                      onClick={() => handPaymentMethodDelete(fields, i, form)}>
                                                  <CloseCircleOutlineIcon/>
                                                </Link>
                                              ) : ''
                                          }
                                        </FormGroupField>
                                      </FormGroup>
                                    </Col>
                                  ))
                                }
                                <FormHalfContainer>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => fields.push({})}
                                  >
                                    <PlusCircleOutlineIcon/> <span>Add</span>
                                  </Button>
                                  {paymentSettingsError ? <Error error={paymentSettingsError}/> : ''}
                                </FormHalfContainer>
                              </Row>
                            )}
                          </FieldArray>
                        </RepeaterWrap>
                      </CardBody>
                    </Card>
                    <Card id="lease-settings">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Lease Settings</CardTitle>
                        </CardTitleWrap>
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <FormGroupLabel>Generate Invoice on (Day of Month)</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="generate_invoice_on"
                                  component={renderSelectField}
                                  options={range(0, 30, 1)}
                                  placeholder="Day of Month"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card id="document-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Documents</CardTitle>
                        </CardTitleWrap>
                        {showDocumentDeleteConfirmBox && (
                          <Row>
                            <ConfirmBox
                              color="primary"
                              message="Are you sure you want to delete?"
                              showHide={showDocumentDeleteConfirmBox}
                              onChangeShowHide={setShowDocumentDeleteConfirmBox}
                              callbackConfirm={confirmDeleteDocument}/>
                          </Row>
                        )}
                        <FieldArray name="documents">
                          {({ fields }) => {
                            return (
                              <>
                                {
                                  fields.map((name, i) => {
                                    return (
                                      <Row key={i}>
                                        <Col md={4}>
                                          <FormGroup>
                                            <FormGroupLabel>Document Type</FormGroupLabel>
                                            <FormGroupField>
                                              <Field
                                                name={`${name}.document_type`}
                                                component={renderSelectField}
                                                options={documentTypes}
                                                placeholder="Select Document Type"
                                              />
                                            </FormGroupField>
                                          </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                          <FormGroup>
                                            <FormGroupLabel>Add file</FormGroupLabel>
                                            <FormGroupField>
                                              <Field
                                                name={`${name}.document`}
                                                component={renderFileInputField}
                                                preview={fields.value[i]?.document_url}
                                              />
                                              {
                                                <Link href={void (0)} onClick={() => {
                                                  deleteDocument(fields, i);
                                                }}>
                                                  <CloseCircleOutlineIcon/>
                                                </Link>
                                              }
                                            </FormGroupField>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    );
                                  })
                                }
                                <FormHalfContainer>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => fields.push({})}
                                  >
                                    <PlusCircleOutlineIcon/> <span>Add Document</span>
                                  </Button>
                                  {documentError ? <Error error={documentError}/> : ''}
                                </FormHalfContainer>
                              </>
                            );
                          }}
                        </FieldArray>
                      </CardBody>
                    </Card>
                    <Row>
                      <Col md={12}>
                        <FormButtonToolbar>
                          <Button variant="primary" type="submit">Submit</Button>
                          <Button variant="secondary" type="button" onClick={form.reset}>
                            Cancel
                          </Button>
                        </FormButtonToolbar>
                      </Col>
                    </Row>
                  </FormContainer>
                );
              }}
            </Form>
          </FormWrap>
        </Col>
      </Row>
    </Container>
  );
};

export default AddLease;


