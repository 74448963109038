import { React, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { transactionCategoryCreate, transactionCategoryUpdate } from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { generateFormInitialValue, prepareData } from '../../../shared/helpers';
import { useParams } from 'react-router-dom';
import { get } from '../../../utils/api/base';
import {  TRANSACTION_CATEGORIES } from '../../App/Router/api_routs';

const AddTransactionCategory = () => {

  const token = getAccessToken();
  const { id } = useParams();
  const [initValue, setInitValue] = useState({});

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Transaction Category"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const handleAddTransactionCategory = async (values, form) => {
    let convertIntKeys = ['status'];
    let data = prepareData({ ...values }, convertIntKeys, []).data;
    if (id) {
      transactionCategoryUpdate({ ...data }, token)
        .then((result) => {
          if (result.data.status == 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    } else {
      transactionCategoryCreate({ ...data }, token)
        .then((result) => {
          if (result.data.status == 201) {
            show('success', result.data.message);
            form.reset();
            for (let key in data) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }
  };

  useEffect(async () => {

    if (id) {
      let transactionCategory = await get(TRANSACTION_CATEGORIES + '/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      });
      transactionCategory = transactionCategory ? (transactionCategory.data.transaction_category) : '';
      if (transactionCategory) {
        let data = generateFormInitialValue(transactionCategory);
        let data_type = data.category_type;
        data.category_type = {
          value: data_type,
          label: data_type.replace(/^./, data_type[0].toUpperCase())
        };
        setInitValue(data);
      }
    }

  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Transaction Information</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Transaction Form</CardTitle>
                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
              </CardTitleWrap>
              <Form onSubmit={(values, e) => handleAddTransactionCategory(values, e)}
                    initialValues={initValue}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  touched
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>Category Type</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="category_type"
                            component={renderSelectField}
                            options={[{
                              value: 'income',
                              label: 'Income'
                            }, {
                              value: 'expense',
                              label: 'Expense'
                            }]}
                            placeholder="Category Type"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Transaction Category</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="name"
                            component={FormField}
                            type="text"
                            placeholder="Transaction Category"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Status</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="status"
                            component={renderSelectField}
                            options={[{
                              value: 1,
                              label: 'Active'
                            }, {
                              value: 2,
                              label: 'Inactive'
                            }]}
                            placeholder="Status"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddTransactionCategory;
