import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Form } from 'react-bootstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import styled from 'styled-components';
import {
    colorAccent,
    colorAdditional,
    colorBackground,
    colorFieldsBorder,
    colorIcon,
    colorText,
} from '@/utils/palette';
import {
    marginRight,
    marginLeft,
} from '@/utils/directions';

const range = (start, end) => {
    return [...Array(end - start).keys()].map((el) => el + start);
};

const getPagesCut = ({ pagesCount, pagesCutCount, currentPage }) => {
    const ceiling = Math.ceil(pagesCutCount / 2);
    const floor = Math.floor(pagesCutCount / 2);

    if (pagesCount < pagesCutCount) {
        return { start: 1, end: pagesCount + 1 };
    } else if (currentPage >= 1 && currentPage <= ceiling) {
        return { start: 1, end: pagesCutCount + 1 };
    } else if (currentPage + floor >= pagesCount) {
        return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
    } else {
        return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
    }
};

const classNames = (classObj) => {
    let classList = [];
    for (const key in classObj) {
        if (classObj[key]) {
            classList.push(key);
        }
    }
    return classList.join(' ');
};

const PaginationItem = ({ page, currentPage, onPageChange, isDisabled }) => {
    const liClasses = classNames({
        "page-item": true,
        active: page === currentPage,
        disabled: isDisabled,
    });

    return (
        <>
            {isDisabled && (
                < li className={liClasses}>
                    <span className="page-link">{page}</span>
                </li >
            )}
            {!isDisabled && (
                < li className={liClasses} onClick={() => onPageChange(page)}>
                    <span className="page-link">{page}</span>
                </li >
            )}
        </>

    );

};

const CustomPagination = ({ currentPage, total, limit, onPageChange, setPageLimit, manualPageSize }) => {
    const pagesCount = Math.ceil(total / limit);
    const pagesCut = getPagesCut({ pagesCount, pagesCutCount: 5, currentPage });
    const pages = range(pagesCut.start, pagesCut.end);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === pagesCount;
    return (
        <>
            <div className='pagination-wrap'>
                {total > limit && (
                    <ul className="pagination">
                        <PaginationItem
                            page="First"
                            currentPage={currentPage}
                            onPageChange={() => onPageChange(1)}
                            isDisabled={isFirstPage}
                        />
                        <PaginationItem
                            page="Prev"
                            currentPage={currentPage}
                            onPageChange={() => onPageChange(currentPage - 1)}
                            isDisabled={isFirstPage}
                        />
                        {pages.map((page) => (
                            <PaginationItem
                                page={page}
                                key={page}
                                currentPage={currentPage}
                                onPageChange={onPageChange}
                            />
                        ))}
                        <PaginationItem
                            page="Next"
                            currentPage={currentPage}
                            onPageChange={() => onPageChange(currentPage + 1)}
                            isDisabled={isLastPage}
                        />
                        <PaginationItem
                            page="Last"
                            currentPage={currentPage}
                            onPageChange={() => onPageChange(pagesCount)}
                            isDisabled={isLastPage}
                        />
                    </ul>
                )}
                {/* <PaginationInfo>
                    Showing {limit * currentPage - limit + 1} to {limit * currentPage} of {total}
                </PaginationInfo>
                {manualPageSize.length > 1 && (
                    <PaginationSelectWrap as={Form.Group}>
                        <PaginationFormSelect
                            name="select"
                            id="exampleSelect"
                            value={limit}
                            onChange={(event) => {
                                setPageLimit(Number(event.target.value));
                            }}
                        >
                            {manualPageSize.map(item => (
                                <PaginationFormOption key={item} value={item}>
                                    Show {item}
                                </PaginationFormOption>
                            ))}
                        </PaginationFormSelect>
                    </PaginationSelectWrap>
                )} */}
            </div>
        </>
    );
};
CustomPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

CustomPagination.defaultProps = {
    manualPageSize: [10, 20, 30, 40],
};
export default CustomPagination;


export const CustomReactBootstrapPagination = ({ currentPage, total, limit, onPageChange, setPageLimit, manualPageSize }) => {
    const reactPagesCount = Math.ceil(total / limit);
    const reactPagesCut = getPagesCut({ pagesCount: reactPagesCount, pagesCutCount: 5, currentPage });
    const reactPages = range(reactPagesCut.start, reactPagesCut.end);
    const isFirstPageReact = currentPage === 1;
    const isLastPageReact = currentPage === reactPagesCount;
    return (
        <PaginationWrap>
            {
                total > limit && (
                    <Pagination>
                        <Pagination.First
                            disabled={isFirstPageReact}
                            className="pagination__item--arrow"
                            onClick={() => onPageChange(1)}
                        >
                            <ChevronDoubleLeftIcon />
                        </Pagination.First>
                        <Pagination.Prev
                            disabled={isFirstPageReact}
                            className="pagination__item--arrow"
                            onClick={() => onPageChange(currentPage - 1)}
                        >
                            <ChevronLeftIcon />
                        </Pagination.Prev>

                        {reactPages.map(page => (
                            <Pagination.Item
                                key={page}
                                active={currentPage === page}
                                onClick={() => onPageChange(page)}
                            >
                                {page}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={isLastPageReact}
                            className="pagination__item--arrow"
                            onClick={() => onPageChange(currentPage + 1)}
                        >
                            <ChevronRightIcon />
                        </Pagination.Next>
                        <Pagination.Last
                            disabled={isLastPageReact}
                            className="pagination__item--arrow"
                            onClick={() => onPageChange(reactPagesCount)}
                        >
                            <ChevronDoubleRightIcon />
                        </Pagination.Last>
                    </Pagination>
                )
            }

            <PaginationInfo>
                Showing {limit * currentPage - limit + 1} to {total ? total : (limit * currentPage)} of {total}
            </PaginationInfo>
            {manualPageSize.length > 1 && (
                <PaginationSelectWrap as={Form.Group}>
                    <PaginationFormSelect
                        name="select"
                        id="exampleSelect"
                        value={limit}
                        onChange={(event) => {
                            setPageLimit(Number(event.target.value));
                        }}
                    >
                        {manualPageSize.map(item => (
                            <PaginationFormOption key={item} value={item}>
                                Show {item}
                            </PaginationFormOption>
                        ))}
                    </PaginationFormSelect>
                </PaginationSelectWrap>
            )}
        </PaginationWrap>
    );
}

CustomReactBootstrapPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

CustomReactBootstrapPagination.defaultProps = {
    manualPageSize: [10, 20, 30, 40],
};



// region STYLES

export const PaginationWrap = styled.div`
            display: flex;
            align-items: center;

            @media screen and (max-width: 768px) {
                flex - direction: column-reverse;
    
    & > * {
                margin: 10px 0;
    }
  }

            .page-item {
                ${marginRight}: 2px;

            .page-link {
                background: transparent;
            border: none;
            color: ${colorAdditional};
            height: 28px;
            font-size: 10px;
            padding: 5px;
            text-align: center;
            min-width: 28px;
            transition: all 0.3s;

            &:hover {
                color: ${colorAccent};
            background: transparent;
      }

            &:focus, &:active {
                box - shadow: none;
      }

            svg {
                width: 13px;
            height: 13px;
            fill: ${colorAdditional};
            transition: 0.3s;
            position: absolute;
            top: 7px;
            left: 8px;
      }
    }

            &.pagination__item--arrow .page-link {
                border - radius: 50%;
            width: 28px;
            height: 28px;
            background: ${colorFieldsBorder};

            &:hover {
                background: ${colorAccent};

            svg {
                fill: white;
        }
      }
    }

            &.pagination__item--arrow.disabled .page-link {
                cursor: default;
            opacity: 0.4;
            background: ${colorFieldsBorder};

            svg {
                fill: ${colorAdditional};
      }
    }

            &.active .page-link {
                background - color: transparent;
            font-weight: bold;
            color: ${colorText};
    }

            &.disabled .page-link svg {
                fill: ${colorIcon};
    }
  }
            `;

const PaginationInfo = styled.div`
            color: ${colorAdditional};
            font-size: 12px;
            ${marginRight}: 0;
            ${marginLeft}: 10px;
            `;

const PaginationSelectWrap = styled(PaginationInfo)`
            ${marginLeft}: 20px;
            color: ${colorAdditional};
            `;

const PaginationFormSelect = styled(Form.Select)`
            color: ${colorAdditional};
            font-size: 12px;
            background-color: ${colorBackground};
            padding: 6px 12px;
            appearance: auto;
            background-image: none;
            `;

const PaginationFormOption = styled.option`
            color: ${colorAdditional};
            font-size: 14px;
            `;