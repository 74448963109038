import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Unauthorized = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-subhead subhead">Ooops! The page you are looking is not authorized</h3>
      </Col>
    </Row>
  </Container>
);

export default Unauthorized;