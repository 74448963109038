import { React, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { DELETE_PROPERTY, GET_PROPERTIES } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { Button } from '@/shared/components/Button';
import { useSelector } from 'react-redux';
import { useNotification, BasicNotification } from '../../shared/components/Notification';
import { Status } from '../../shared/constants/commonConstnt';
import { rand } from '../../shared/helpers';

export const PropertyList = () => {
  const module = 'Property';
  const token = getAccessToken();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState({});
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);

  const history = useHistory();
  const editData = (id) => {
    let path = `edit/${id}`;
    history.push(path);
  };

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const deleteClickHandle = (e, propId) => {
    setShowHideConfirmBox(propId);
  };

  const deleteProperty = (property) => {
    deleteRequest(DELETE_PROPERTY + '/' + property, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response?.status == 200) {
          loadData();
          setShowHideConfirmBox(false);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });
  };

  const loadData = async () => {
    let urlParams = `?page=${currentPage}&limit=${pageSize}`;
    const result = await get(GET_PROPERTIES + urlParams, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    if (result) {
      setData([]);//set data does not worked unless set this empty.
      let propertyData = result?.data?.properties?.data;
      if (propertyData) {
        propertyData.map((item) => {
          item.status = Status[item.status];
        });
      }
      setData(propertyData);
      setTotalData(result?.data?.properties?.total);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable:true
      },
      {
        Header: 'Address',
        accessor: 'address1',
        sortable: true
      },
      {
        Header: 'State',
        accessor: 'province.province_name',
        sortable:true
      },
      {
        Header: 'City',
        accessor: 'city',
        sortable:true
      },
      {
        Header: 'Property Type',
        accessor: 'property_type.property_type',
        sortable:true
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable:true
      },
      {
        Header: 'Actions',
        accessor: '[actionButtons]',
        sortable:false,
        Cell: (cellObj) => (
          <>
            <Button variant="outline-primary" size="sm" marginBottom="0"
                    onClick={() => editData(cellObj.row.original.id)}>Edit</Button>
            {!cellObj.row.original.is_unit_active && //cellObj.row.original.status !== 'Active' &&
              <Button variant="outline-danger" size="sm" marginBottom="0"
                      onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)}>Delete</Button>
            }
          </>
        )
      }
    ]
  );

  const pageSettings = ({
    total: totalData,
    currentPage,
    limit: pageSize,
    customPagination: true,
  });

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{module} Information</h3>
        </Col>
      </Row>
      {showHideConfirmBox && (
        <Row>
          <ConfirmBox
            color="primary"
            message="Are you sure you want to delete?"
            showHide={showHideConfirmBox}
            onChangeShowHide={setShowHideConfirmBox}
            callbackConfirm={deleteProperty}/>
        </Row>
      )}
      <Row>
        {isFetching && (
          <div className="text-center">
            <TableSpinner animation="border"/>
          </div>
        )}
        {data && data.length > 0 && !isFetching && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: data,
                pageSettings: pageSettings
              }}
              module={module}
              setPage={(page) => setCurrentPage(page)}
              setLimit={(limit) => setPageSize(limit)}
              showNotification={show}
            />
          </Col>
        )}
        {!data.length && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: [],
                pageSettings: pageSettings
              }}
              module={module}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
