import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import {UserRoles} from "../../../../shared/constants/userroles";
import { TransactionCategoryList } from '../../../TransactionCategory';
import AddTransactionCategory from '../../../TransactionCategory/Components/add';

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/transaction-category/list" component={TransactionCategoryList} />
            <Route path="/transaction-category/add" component={AddTransactionCategory} />
            <Route path="/transaction-category/edit/:id" component={AddTransactionCategory} />
        </PermissionRoute>
    </Switch>
);
