import {
  ELECTRICITIES_SAVE,
  ELECTRICITY_TYPES,
  INVOICE_DETAILS_SAVE,
  INVOICE_DETAILS_STORE,
  INVOICES,
  LOGIN_USER,
  SAVE_DOCUMENT_TYPE,
  SAVE_DOCUMENTS,
  SAVE_LEASE_PROPERTY,
  SAVE_PROPERTY,
  PROPERTY_TYPES,
  PROPERTY_UNITS,
  SAVE_ROLE,
  SAVE_SETTINGS,
  SAVE_USER,
  TRANSACTION_CATEGORIES,
  TRANSACTIONS,
  UPDATE_PROPERTY,
  UPDATE_USER,
  UPDATE_DOCUMENT_TYPE,
  PROFILE_PICTURE_SAVE,
  UPDATE_USER_PASSWORD, FACILITIES, PAYMENT_METHOD, SAVE_FEES, SAVE_UTILITY, SAVE_PAYMENT_SETTINGS,
} from '../containers/App/Router/api_routs';
import { post, put } from './api/base';
import { login } from '../shared/components/account/auth/withAuth';
import store from '../containers/App/store';
import { authError } from '../redux/actions/authActions';

export default (async function handleLogin(values) {
  const res = post(LOGIN_USER, values, {
    headers: { Accept: 'application/json' },
  });
  res.then((response) => {
    login(response);
  })
    .catch((e) => {
      store.dispatch(authError(e));
    });
});

export const roleSubmit = (values, token) => {
  if (values) {
    return post(SAVE_ROLE, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const userCreate = (values, token) => {
  if (values) {
    return post(SAVE_USER, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const userUpdate = (id, values, token) => {
  if (values) {
    return put(`${UPDATE_USER}/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const userPasswordUpdate = (id, values, token) => {
  if (values) {
    return put(`${UPDATE_USER_PASSWORD}/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const documentTypeCreate = (values, token) => {
  if (values) {
    return post(SAVE_DOCUMENT_TYPE, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const documentTypeUpdate = (values, token) => {
  if (values) {
    return put(UPDATE_DOCUMENT_TYPE + `/${values.id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyTypeCreate = (values, token) => {
  if (values) {
    return post(PROPERTY_TYPES, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyTypeUpdate = (values, token) => {
  if (values) {
    return put(PROPERTY_TYPES + `/${values.id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyCreate = (values, token) => {
  if (values) {
    return post(SAVE_PROPERTY, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyUpdate = (id, values, token) => {
  if (values) {
    return post(`${UPDATE_PROPERTY}/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyUnitCreate = (values, token) => {
  if (values) {
    return post(PROPERTY_UNITS, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyUnitUpdate = (id, values, token) => {
  if (values) {
    return put(`${PROPERTY_UNITS}/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const documentSave = (values, token) => {
  if (values) {
    return post(SAVE_DOCUMENTS, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const propertyLeaseSave = (values, token) => {
  if (values) {
    return post(SAVE_LEASE_PROPERTY, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const facilityCreate = (values, token) => {
  if (values) {
    return post(FACILITIES, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const facilityUpdate = (values, token) => {
  if (values) {
    return put(FACILITIES + `/${values.id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const feeSave = (values, token) => {
  if (values) {
    return post(SAVE_FEES, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const utilitySave = (values, token) => {
  if (values) {
    return post(SAVE_UTILITY, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const paymentSettingsSave = (values, token) => {
  if (values) {
    return post(SAVE_PAYMENT_SETTINGS, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const settingsSave = (values, token) => {
  if (values) {
    return post(SAVE_SETTINGS, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const invoiceSave = (values, token) => {
  if (values) {
    let url = INVOICES;
    if (values.id) {
      url += `/${values.id}`;
      return put(url, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
    }
    return post(url, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const invoiceDetailsCreate = (values, token) => {
  if (values) {
    return post(INVOICE_DETAILS_STORE, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const invoiceDetailsSave = (values, token) => {
  if (values) {
    return post(INVOICE_DETAILS_SAVE, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const transactionCategoryCreate = (values, token) => {
  if (values) {
    return post(TRANSACTION_CATEGORIES, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const transactionCategoryUpdate = (values, token) => {
  if (values) {
    const id = values.id;
    return put(TRANSACTION_CATEGORIES + '/' + id, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const transactionCreate = (values, token) => {
  if (values) {
    return post(TRANSACTIONS, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const transactionUpdate = (values, token) => {
  if (values) {
    const id = values.id;
    return put(TRANSACTIONS + '/' + id, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const profilePictureSave = (values, token) => {
  if (values) {
    return post(PROFILE_PICTURE_SAVE, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const paymentMethodCreate = (values, token) => {
  if (values) {
    return post(PAYMENT_METHOD, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};

export const paymentMethodUpdate = (values, id, token) => {
  if (values) {
    return post(PAYMENT_METHOD + `/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
  return null;
};
