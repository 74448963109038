/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.tenant) {
    errors.tenant = 'Tenant field is required.';
  }

  if (!values.property_id) {
    errors.property_id = 'Property field is required.';
  }

  if (!values.unit_id) {
    errors.unit_id = 'Unit field is required.';
  }
  if (!values.amount) {
    errors.amount = 'Amount field is required.';
  }
  if (!values.start_date) {
    errors.start_date = 'Start field is required.';
  }
  if (!values.end_date) {
    errors.end_date = 'End field is required.';
  }
  /*if (!values.due_on) {
    errors.due_on = 'Due on field is required.';
  }*/
  if (!values.status) {
    errors.status = 'Status field is required.';
  }
  if (!values.deposit_type) {
    errors.deposit_type = 'Type field is required.';
  }
  if (values.deposit_type == 'cash' && !values.deposit_amount) {
    errors.deposit_amount = 'Deposit amount field is required.';
  }

  /***************************** lateFeeDiscount validation start ***********************************/
  /*if (!values.lateFeeDiscount || !values.lateFeeDiscount.length) {
    errors.lateFeeDiscount = 'Required at least one Late Fee or Discount.';
  }*/
  if (values.lateFeeDiscount) {
    const lateFeeDiscountsErrors = [];

    values.lateFeeDiscount.forEach((item, index) => {
      const lateFeeDiscountErrors = {};

      if (!item.fee_type) {
        lateFeeDiscountErrors.fee_type = 'Fee type field is required.';
      }

      if (!item.fee_value_type) {
        lateFeeDiscountErrors.fee_value_type = 'Fee value type field is required.';
      }

      if (typeof item['fee_value_type'] !== 'undefined' && item.fee_value_type.value === 'percentage' && !item.percentage_type) {
        lateFeeDiscountErrors.percentage_type = 'Percentage type field is required.';
      }

      if (typeof item['fee_value_type'] !== 'undefined' && item.fee_value_type.value === 'percentage' &&
        typeof item['percentage_type'] !== 'undefined' && item.percentage_type.value === 'on_utility' && !item.utility_id) {
        lateFeeDiscountErrors.utility_id = 'Utility field is required.';
      }

      if (!item.fee_value) {
        lateFeeDiscountErrors.fee_value = 'Value type field is required.';
      }

      if (!item.grace_period) {
        lateFeeDiscountErrors.grace_period = 'Grace period type field is required.';
      }

      if (!item.frequency) {
        lateFeeDiscountErrors.frequency = 'Frequency type field is required.';
      }

      if (Object.keys(lateFeeDiscountErrors).length) {
        lateFeeDiscountsErrors[index] = lateFeeDiscountErrors;
      }
    });

    if (lateFeeDiscountsErrors.length) {
      errors.lateFeeDiscount = lateFeeDiscountsErrors;
    }
  }

  /***************************** utilities validation start ***********************************/
  if (!values.utilities || !values.utilities.length) {
    errors.utilities = 'Required at least one utility.';
  }
  if (values.utilities) {
    const utilitiesErrors = [];

    values.utilities.forEach((item, index) => {
      const utilityErrors = {};

      if (!item.facility_id) {
        utilityErrors.facility_id = 'Utility field is required.';
      }

      /*if (!item.initial_unit) {
        utilityErrors.initial_unit = 'Initial unit field is required.';
      }*/

      /*if (!item.minimum_charge) {
        utilityErrors.minimum_charge = 'Minimum charge field is required.';
      }*/

      if (!item.variable_charge && !item.fixed_charge) {
        utilityErrors.variable_charge = 'Variable charge field is required.';
      }

      if (!item.fixed_charge && !item.variable_charge) {
        utilityErrors.fixed_charge = 'Fixed charge field is required.';
      }

      if (Object.keys(utilityErrors).length) {
        utilitiesErrors[index] = utilityErrors;
      }
    });

    if (utilitiesErrors.length) {
      errors.utilities = utilitiesErrors;
    }
  }

  /***************************** utilities validation start ***********************************/
  if (!values.paymentSettings || !values.paymentSettings.length) {
    errors.paymentSettings = 'Required at least one payment settings.';
  }
  if (values.paymentSettings) {
    const paymentSettingsErrors = [];

    values.paymentSettings.forEach((item, index) => {
      const paymentSettingErrors = {};

      if (!item.payment_method_id) {
        paymentSettingErrors.payment_method_id = 'Payment method field is required.';
      }

      if (Object.keys(paymentSettingErrors).length) {
        paymentSettingsErrors[index] = paymentSettingErrors;
      }
    });

    if (paymentSettingsErrors.length) {
      errors.paymentSettings = paymentSettingsErrors;
    }
  }

  if (!values.generate_invoice_on) {
    errors.generate_invoice_on = 'Generate invoice on field is required.';
  }

  /***************************** utilities validation start ***********************************/
  /*if (!values.documents || !values.documents.length) {
    errors.documents = 'Required at least one document.';
  }
  /**/
  if (values.documents) {
    const documentsErrors = [];

    values.documents.forEach((item, index) => {
      const documentErrors = {};

      if (!item.document_type) {
        documentErrors.document_type = 'Document type field is required.';
      }

      if (!item.document) {
        documentErrors.document = 'File field is required.';
      }

      if (Object.keys(documentErrors).length) {
        documentsErrors[index] = documentErrors;
      }
    });

    if (documentsErrors.length) {
      errors.documents = documentsErrors;
    }
  }

  return errors;
};
export default validate;
