import { React, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { facilityCreate, facilityUpdate } from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from '../../../utils/api/base';
import { FACILITIES } from '../../App/Router/api_routs';
import { generateFormInitialValue, prepareData } from '../../../shared/helpers';

const AddUtilities = () => {

  const token = getAccessToken();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState([]);

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Utilities"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const handleAddUtilities = async (values, form) => {
    let data = [];
    let convertIntKeys = ['status'];
    const utilitiesData = prepareData(values, convertIntKeys);
    data = { ...(utilitiesData.data) };
    if (id) {
      facilityUpdate(data, token)
        .then((result) => {
          if (result.data.status == 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        })
        .catch(function (e) {
          show('danger', e.response.data.message);
        });
    } else {
      facilityCreate(data, token)
        .then((result) => {
          if (result.data.status == 201) {
            show('success', result.data.message);
            form.reset();
            for (let key in data) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        }).catch(function (e){
          show('danger', e.response.data.message);
      });
    }
  };

  useEffect(async () => {
    if (id) {
      let facilities = await get(FACILITIES + `/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      });
      facilities = facilities ? facilities.data.facility : '';
      if (facilities) {
        let data = generateFormInitialValue(facilities);
        setInitialValues(data);
      }
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Utilities</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add Utilities</CardTitle>
                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
              </CardTitleWrap>
              <Form onSubmit={(values, e) => handleAddUtilities(values, e)}
                    initialValues={initialValues}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  touched
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>Utilities</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="facility"
                            component={FormField}
                            type="text"
                            placeholder="Utilities"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Status</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="status"
                            component={renderSelectField}
                            options={[{
                              value: 1,
                              label: 'Active'
                            }, {
                              value: 2,
                              label: 'Inactive'
                            }]}
                            placeholder="Status"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUtilities;
