import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import {
  MaterialFormContainer,
  MaterialTextField,
  MaterialFormLabel,
} from '@/shared/components/form/MaterialFormElements';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';
import validate from './changePasswordValidate';

const renderTextField = ({
  input,
  label,
  meta: {
    touched,
    error
  },
}) => (
  <MaterialTextField
    label={label}
    error={!!error}
    value={input.value}
    type={input.type}
    helperText={ error ? error : ''}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};

const ChangePassword = ({
  onSubmit,
  t,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  return (
    <Form onSubmit={(values, e) => onSubmit(values, e)} validate={validate}>
      {({
        handleSubmit,
        form
      }) => (
        <MaterialFormContainer onSubmit={handleSubmit}>
          <div>
            <MaterialFormLabel>{t('Current Password')}</MaterialFormLabel>
            <FieldWrap>
              <Field
                name="current_password"
                component={renderTextField}
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
              />
              <span className="lnr lnr-eye" onClick={handleClick}></span>
            </FieldWrap>
          </div>
          <div>
            <MaterialFormLabel>{t('New Password')}</MaterialFormLabel>
            <FieldWrap>
              <Field
                name="password"
                component={renderTextField}
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
              />
              <span className="lnr lnr-eye" onClick={handleClick}></span>
            </FieldWrap>
          </div>
          <div>
            <MaterialFormLabel>{t('Confirm Password')}</MaterialFormLabel>
            <FieldWrap>
              <Field
                name="password_confirmation"
                component={renderTextField}
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
              />
              <span className="lnr lnr-eye" onClick={handleClick}></span>
            </FieldWrap>
          </div>
          <FormButtonToolbar>
            <Button variant="primary" type="submit">{t('Change Password')}</Button>
          </FormButtonToolbar>
        </MaterialFormContainer>
      )
      }
    </Form>
  );
};

export default ChangePassword;

const FieldWrap = styled.div`
    position: relative;

    span {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px 8px;
        font-size: 16px;
    }
`;

ChangePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
