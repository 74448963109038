import axios from 'axios';
import { getToken } from '../../helpers';
import { END_POINT } from '../../../containers/App/Router/api_routs';

export const apiUrl = 'https://c-proxy.int.aspirity.com';

export const defaultParams = () => ({
  headers: { Authorization: `Bearer ${getToken()}` },
});

export default axios.create({
  baseURL: END_POINT,
});
