/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.unit_name) {
    errors.unit_name = 'Unit Name field is required.';
  }
  if (!values.property_id) {
    errors.property_id = 'Property field is required.';
  }
  if (!values.total_area) {
    errors.total_area = 'Total Area field is required.';
  }
  if (!values.status) {
    errors.status = 'Status field is required.';
  }
  return errors;
};

export default validate;
