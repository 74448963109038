/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.category_type) {
    errors.category_type = 'Category type field is required.';
  }
  if (!values.name) {
    errors.name = 'Category name field is required.';
  }
  if (!values.status) {
    errors.status = 'Category status field is required.';
  }
  return errors;
};

export default validate;
