import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserRoles } from '../../../../shared/constants/userroles';
import { Lease } from '../../../Lease';
import AddLease from '../../../Lease/Components/add';
import ViewLease from '../../../Lease/Components/view';

export default () => (
  <Switch>
    <Route path="/lease/list" component={Lease}/>
    <Route path="/lease/view/:id" component={ViewLease}/>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/lease/add" component={AddLease}/>
      <Route path="/lease/edit/:id" component={AddLease}/>
    </PermissionRoute>
  </Switch>
);
