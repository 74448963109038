import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import styled from 'styled-components';
import { get, put } from '../../../utils/api/base';
import { GET_DOCUMENT_TYPES, GET_ROLES, UPDATE_ROLE } from '../../App/Router/api_routs';
import { getAccessToken } from '../../../utils/helpers';
import { SearchInput, SearchSelect, SearchWrap } from '../../../shared/components/SearchStyles';
import { encodeDataToURL } from '../../../shared/helpers';
import { Status } from '../../../shared/constants/commonConstnt';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ListTable = ({
  reactTableData,
  module,
  setPage,
  setLimit,
  showNotification
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [isEditable, setIsEditable] = useState(true);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
  const [search, setSearch] = useState([]);
  const [pageSettings, setPageSettings] = useState(reactTableData.pageSettings);
  const {
    currentPage,
    total,
    limit,
    customPagination
  } = reactTableData.pageSettings;

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const token = getAccessToken();

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        const data = {
          'name': value
        };
        setIsEditable(false);
        put(UPDATE_ROLE + '/' + item.id, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          }
        })
          .then(function (response) {
            if (response.data.status == 201) {
              setIsEditable(true);
              showNotification('success', response.data.message);
            } else {
              showNotification('danger', response.data.message);
            }
          })
          .catch(function (e) {
            console.log(e);
            if (e?.data?.status != 400) {
              console.log(e?.data?.message);
              showNotification('danger', e?.data?.message);
            }
          });
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const handleClickEditRow = () => {
    setIsEditable(true);
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
    pageSettings: pageSettings,
  };

  const onChange = async (e) => {
    let searchList = search;
    searchList[e.target.name] = e.target.value;
    searchList['page'] = currentPage;
    searchList['limit'] = limit;
    const result = await get(GET_ROLES, {
      params: { ...searchList },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    if (result) {
      setData(result?.data?.roles.data);
      let tot = result?.data?.roles?.total;
      setPageSettings({
        currentPage,
        total: tot,
        limit,
        customPagination
      });
    }
  };
  const handleSelectChange = (selectedOption, e) => {
    let se = {
      target: {
        name: e.name,
        value: selectedOption.value
      }
    };
    onChange(se);
  };

  useEffect(async () => {
    /*const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return () => {
        source.cancel();
    };*/
  }, [rows]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>{module} List</CardTitle>

              {/* <CardSubhead>
                                Use table with&nbsp;
                                <span className="red-text">table customizer</span>
                            </CardSubhead> */}

            </CardTitleWrap>
            {/* <ReactTableCustomizer
                            handleClickIsEditable={handleClickIsEditable}
                            handleClickIsResizable={handleClickIsResizable}
                            handleClickIsSortable={handleClickIsSortable}
                            handleClickWithDragAndDrop={handleClickWithDragAndDrop}
                            handleClickWithPagination={handleClickWithPagination}
                            handleClickWithSearchEngine={handleClickWithSearchEngine}
                            isEditable={isEditable}
                            isResizable={isResizable}
                            isSortable={isSortable}
                            isDisabledDragAndDrop={isDisabledDragAndDrop}
                            isDisabledEditable={isDisabledEditable}
                            isDisabledResizable={isDisabledResizable}
                            withDragAndDrop={withDragAndDrop}
                            withPagination={withPagination}
                            withSearchEngine={withSearchEngine}
                            fullCustomizer
                        /> */}
          </HeaderWrap>
          <SearchWrap className="row">
            <Container>
              <Row>
                <Col md={4}>
                  <SearchInput
                    type="text"
                    name="name"
                    placeholder="Role"
                    onChange={(e) => onChange(e)}
                    value={search['role']}
                  />
                </Col>
              </Row>
            </Container>
          </SearchWrap>
          <ReactTableBase
            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={rows}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            //handleClickEditRow={handleClickEditRow}
            tableConfig={tableConfig}
            setPage={setPage}
            setLimit={setLimit}
          />

        </CardBody>
      </Card>
    </Col>
  );
};

ListTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  showNotification: PropTypes.func,
};
ListTable.defaultProps = {
  setPage: () => {
  },
  setLimit: () => {
  },
  showNotification: () => {
  }
};

export default ListTable;

// region STYLES

const HeaderWrap = styled.div`
    display: flex;

    & > div:first-child {
        margin-right: auto;
    }
`;

// endregion
