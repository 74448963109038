import React, { useEffect, useState } from 'react';
import {
  Col,
  Nav,
  Tab,
} from 'react-bootstrap';
import ProfileTimeLine from '@/shared/components/ProfileTimeLine';
import {
  TabsWrap, NavLink, NavItem, BorderedBottomTabs,
} from '@/shared/components/Tabs';
import { Card } from '@/shared/components/Card';
import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';
import { ProfileCard } from '../ProfileBasicComponents';
import { useTranslation } from 'react-i18next';
import { prepareData } from '../../../../shared/helpers';
import {
  userUpdate,
  documentSave, userPasswordUpdate
} from '../../../../utils/handleSubmit';
import { UserRoles as UserRole } from '../../../../shared/constants/userroles';
import { BasicNotification, useNotification } from '../../../../shared/components/Notification';
import { useSelector } from 'react-redux';

const ProfileTabs = ({
  userData,
  countries,
  provinces,
  token,
  source
}) => {
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation('common');
  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const saveDocument = (documents, user_id, form) => {
    form = form || null;
    documents.forEach(function (document) {
      document.user_id = parseInt(user_id);
      const documentData = prepareData(document, ['document_type', 'user_id']);
      const formData = documentData.form_data;
      const docData = { ...(documentData.data) };
      documentSave(formData, token)
        .then((result) => {
          if (result.data.status === 201) {
            show('success', result.data.message);
            if (form && !id) {
              form.reset();
              for (const key in docData) {
                form.resetFieldState(key);
              }
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const profileSave = (values, form) => {
    const { documents } = values;
    const convertIntKeys = ['primary_country_id', 'primary_state_id', 'secondary_country_id', 'primary_zip_code'];
    const excludeKeys = ['documents', 'name', 'email'];
    let data = prepareData(values, convertIntKeys, excludeKeys).data;
    data.primary_name = values.name;
    data.primary_email = values.email;
    data.id = userData?.user_details?.id;
    data.user_id = userData?.id;
    data = { ...data };
    userUpdate(data.user_id, data, token)
      .then((result) => {
        if (result.data.status === 200) {
          if (userData.role === UserRole.TENANT) {
            const user_id = result.data.user_detail.user_id;
            saveDocument(documents, user_id, form);
          }
          show('success', result.data.message);
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });
  };

  const changePasswordSubmit = (values, form) =>{
    const user_id = userData?.id;
    userPasswordUpdate(user_id, values, token).then((result) => {
      if (result.data.status === 200) {
        show('success', result.data.message);
      } else {
        show('danger', result.data.errors.join('<br/>'));
      }
    }).catch(function (e){
      show('danger', e.response.data.message);
    });
  }

  useEffect(() => {
    if (userData) {
      const user = {
        name: userData.name,
        email: userData.email,
        primary_contact_number: userData?.user_details?.primary_contact_number,
        primary_address_line1: userData?.user_details?.primary_address_line1,
        primary_address_line2: userData?.user_details?.primary_address_line2,
        primary_country_id: {
          value: userData?.user_details?.primary_country_id,
          label: userData?.user_details?.primary_country?.en_short_name
        },
        primary_state_id: {
          value: userData?.user_details?.primary_state_id,
          label: userData?.user_details?.primary_province?.province_name
        },
        primary_city: userData?.user_details?.primary_city,
        primary_zip_code: userData?.user_details?.primary_zip_code,
      };
      setInitialValues(user);
    }
  }, []);
  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <BorderedBottomTabs as={ProfileCard}>
          <Tab.Container defaultActiveKey="3">
            <TabsWrap>
              <Nav className="nav-tabs">
                {/*<NavItem>
                  <NavLink eventKey="1">
                    Activity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="2">
                    TimeLine
                  </NavLink>
                </NavItem>*/}
                <NavItem>
                  <NavLink eventKey="3">
                    {t('Profile Details')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="4">
                    {t('Change Password')}
                  </NavLink>
                </NavItem>
              </Nav>
              <Tab.Content>
                {/*<Tab.Pane eventKey="1">
                  <ProfileActivities />
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <ProfileTimeLine />
                </Tab.Pane>*/}
                <Tab.Pane eventKey="3">
                  <ProfileSettings onSubmit={profileSave} initialValues={initialValues} t={t} countries={countries}
                                   provinces={provinces}/>
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="4">
                  <ChangePassword onSubmit={changePasswordSubmit} t={t}/>
                </Tab.Pane>
              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </BorderedBottomTabs>
      </Card>
    </Col>
  );
};

export default ProfileTabs;
