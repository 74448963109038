import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import {UserRoles} from "../../../../shared/constants/userroles";
import { Utilities } from '../../../Utilities';
import AddUtilities from '../../../Utilities/Components/add';

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/utilities/list" component={Utilities}/>
            <Route path="/utilities/add" component={AddUtilities}/>
            <Route path="/utilities/edit/:id" component={AddUtilities}/>
        </PermissionRoute>
    </Switch>
);
