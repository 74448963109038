import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserRoles } from '../../../../shared/constants/userroles';
import { PropertyUnitList } from '../../../PropertyUnit';
import AddPropertyUnit from '../../../PropertyUnit/Components/add';

export default () => (
  <Switch>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/property-unit/list" component={PropertyUnitList}/>
      <Route path="/property-unit/add" component={AddPropertyUnit}/>
      <Route path="/property-unit/edit/:id" component={AddPropertyUnit}/>
    </PermissionRoute>
  </Switch>
);
