import { React, useState, useEffect, useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { FACILITIES } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import { Button } from '@/shared/components/Button';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { BasicNotification, useNotification } from '../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { Status } from '../../shared/constants/commonConstnt';
import { useHistory } from 'react-router';
import { utilities } from '../../shared/helpers/getDatas';

export const Utilities = () => {
  const module = 'Utilities';
  const token = getAccessToken();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState({});
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    let urlParams = `?page=${currentPage}&limit=${pageSize}`;
    await utilities(token, setData, setTotalData, setIsFetching, urlParams);
  }, [currentPage, pageSize]);

  const editData = (e, id) => {
    history.push(`edit/${id}`);
  };

  const deleteClickHandle = (e, pTypeId) => {
    setShowHideConfirmBox(pTypeId);
  };

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Utilities"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const deleteFacility = (facility) => {
    deleteRequest(FACILITIES + '/' + facility, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response?.status == 200) {
          loadData();
          setShowHideConfirmBox(false);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'S.N.',
        sortable: false,
        Cell: (cell) => (<>{cell.row.index + 1}</>)
      },
      {
        Header: 'Utilities',
        accessor: 'facility',
        sortable: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: true
      },
      {
        Header: 'Actions',
        accessor: '[actionButtons]',
        sortable: false,
        Cell: (cellObj) => (
          <>
            <Button variant="outline-primary" size="sm" marginBottom="0"
                    onClick={(e) => editData(e, cellObj.row.original.id)}>Edit</Button>
            <Button variant="outline-danger" size="sm" marginBottom="0"
                    onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)}>Delete</Button>
          </>
        )
      }
    ]
  );

  const pageSettings = ({
    total: totalData,
    currentPage,
    limit: pageSize,
    customPagination: true,
  });

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{module}</h3>
        </Col>
      </Row>
      {showHideConfirmBox && (
        <Row>
          <ConfirmBox
            color="primary"
            message="Are you sure you want to delete?"
            showHide={showHideConfirmBox}
            onChangeShowHide={setShowHideConfirmBox}
            callbackConfirm={deleteFacility}/>
        </Row>
      )}
      <Row>
        {isFetching && (
          <div className="text-center">
            <TableSpinner animation="border"/>
          </div>
        )}
        {data && data.length > 0 && !isFetching && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: data,
                pageSettings: pageSettings
              }}
              module={module}
              setPage={(page) => setCurrentPage(page)}
              setLimit={(limit) => setPageSize(limit)}
              showNotification={show}
            />
          </Col>
        )}
        {!data.length && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: [],
                pageSettings: pageSettings
              }}
              module={module}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
