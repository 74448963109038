export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_LOGOUT_AUTH = 'AUTHENTICATE_LOGOUT_AUTH';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';

export function auth({
 name, avatar, accessToken, userData,
}) {
  return {
    type: AUTHENTICATE,
    user: {
 name, avatar, accessToken, userData,
},
  };
}

export function authLogout() {
  return {
    type: AUTHENTICATE_LOGOUT_AUTH,
    user: null,
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}
