import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderFileInputField from '@/shared/components/form/FileInput';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BasicNotification, useNotification } from '../../shared/components/Notification';
import { getAccessToken } from '../../utils/helpers';
import { setInitSettingsValue } from '../../shared/helpers/getDatas';
import { settingsSave } from '../../utils/handleSubmit';
import { prepareData } from '../../shared/helpers';

const AddSettings = () => {
  const token = getAccessToken();
  const [initValue, setInitValue] = useState({});

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handleSettingsSubmit = (values, form) => {
    const settingsData = prepareData(values, []);
    const formData = settingsData.form_data;
    settingsSave(formData, token)
      .then((result) => {
        if (result.data.status === 201) {
          show('success', result.data.message);
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    await setInitSettingsValue(token, source, setInitValue);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Settings</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <div className="form-wrap" data-token={token}>
            <Form
              onSubmit={(values, e) => handleSettingsSubmit(values, e)}
              initialValues={initValue}
              mutators={{ ...arrayMutators }}
            >
              {({
                handleSubmit,
                form,
                errors,
                reset,
              }) => {
                return (
                  <FormContainer onSubmit={handleSubmit}>
                    <Card id="info-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Primary Settings</CardTitle>
                        </CardTitleWrap>
                        <FormGroup>
                          <FormGroupLabel>Company Name</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="company_name"
                              component={FormField}
                              type="text"
                              placeholder="Company Name"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Company Email</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="company_email"
                              component={FormField}
                              type="email"
                              placeholder="Company Email"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Company Address</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="company_address"
                              component={FormField}
                              type="text"
                              placeholder="Company Address"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <Row>
                          <Col md={3}>
                            <FormGroup>
                              <FormGroupLabel>Light Theme Logo</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="logo"
                                  component={renderFileInputField}
                                  preview={initValue.logo_url}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <FormGroupLabel>Dark theme Logo</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="dark_logo"
                                  component={renderFileInputField}
                                  preview={initValue.dark_logo_url}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <FormGroupLabel>Favicon</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="favicon"
                                  component={renderFileInputField}
                                  preview={initValue.favicon_url}
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">Save</Button>
                    </FormButtonToolbar>
                  </FormContainer>
                );
              }}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default AddSettings;
