import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PermissionRoute} from '../../../../shared/components/ProtectedRoute';
import {UserRoles} from "../../../../shared/constants/userroles";
import {PropertyList} from "../../../Properties";
import AddProperty from "../../../Properties/Components/add";

export default () => (
    <Switch>
        <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
            <Route path="/properties/list" component={PropertyList}/>
            <Route path="/properties/add" component={AddProperty}/>
             <Route path="/properties/edit/:id" component={AddProperty} />
        </PermissionRoute>
    </Switch>
);