import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import styled from 'styled-components';
import { get, put } from '../../../utils/api/base';
import { FACILITIES } from '../../App/Router/api_routs';
import { getAccessToken } from '../../../utils/helpers';
import { Status } from '../../../shared/constants/commonConstnt';
import { encodeDataToURL, getKeyByValue, ucFirst } from '../../../shared/helpers';
import { SearchInput, SearchSelect, SearchWrap } from '../../../shared/components/SearchStyles';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ListTable = ({
  reactTableData,
  module,
  setPage,
  setLimit,
  showNotification
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
  const [search, setSearch] = useState([]);
  const [pageSettings, setPageSettings] = useState(reactTableData.pageSettings);
  const {
    currentPage,
    total,
    limit,
    customPagination
  } = reactTableData.pageSettings;

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const token = getAccessToken();

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        if (columnId == 'status') {
          value = parseInt(getKeyByValue(Status, ucFirst(value.toLowerCase())));
        }
        const data = {
          [columnId]: value
        };
        setIsEditable(false);
        put(FACILITIES + '/' + item.id, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          }
        })
          .then(function (response) {
            if (response.data.status == 200) {
              setIsEditable(true);
              showNotification('success', response.data.message);
            } else {
              showNotification('danger', response.data.message);
            }
          })
          .catch(function (e) {
            if (e?.data?.status != 400) {
              showNotification('danger', e?.data?.message);
            }
          });
        let updatedData = {
          ...old[rowIndex],
          [columnId]: value,
        };
        if ('status' == columnId) {
          updatedData = {
            ...old[rowIndex],
            [columnId]: Status[value],
          };
        }
        return updatedData;
      }
      return item;
    }));
  };

  const handleClickEditRow = () => {
    setIsEditable(true);
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
    pageSettings: pageSettings,
  };

  const onChange = async (e) => {
    let searchList = search;
    searchList[e.target.name] = e.target.value;
    searchList['page'] = currentPage;
    searchList['limit'] = limit;
    ;
    const result = await get(FACILITIES, {
      params: { ...searchList },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    if (result) {
      let facilityData = result?.data?.facilities.data;
      if (facilityData) {
        facilityData.map((item) => {
          item.status = Status[item.status];
        });
      }
      setData(facilityData);
      let tot = result?.data?.facilities?.total;
      setPageSettings({
        currentPage,
        total: tot,
        limit,
        customPagination
      });
    }
  };
  const handleSelectChange = (selectedOption, e) => {
    let se = {
      target: {
        name: e.name,
        value: selectedOption.value
      }
    };
    onChange(se);
  };

  useEffect(async () => {
    /*const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return () => {
        source.cancel();
    };*/
  }, [rows]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <HeaderWrap>
            <CardTitleWrap>
              <CardTitle>{module} List</CardTitle>
            </CardTitleWrap>
          </HeaderWrap>
          <SearchWrap className="row">
            <Container>
              <Row>
                <Col md={4}>
                  <SearchInput
                    type="text"
                    name="name"
                    placeholder="Utilities"
                    onChange={(e) => onChange(e)}
                    value={search['name']}
                  />
                </Col>
                <Col md={4}>
                  <SearchSelect
                    name="status"
                    onChange={handleSelectChange}
                    options={[{
                      value: '',
                      label: 'Status'
                    }, {
                      value: 1,
                      label: 'Active'
                    }, {
                      value: 2,
                      label: 'Inactive'
                    }]}
                    placeholder="Status"
                  />
                </Col>
              </Row>
            </Container>
          </SearchWrap>
          <ReactTableBase
            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={rows}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
            setPage={setPage}
            setLimit={setLimit}
          />

        </CardBody>
      </Card>
    </Col>
  );
};

ListTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  showNotification: PropTypes.func,
};
ListTable.defaultProps = {
  setPage: () => {
  },
  setLimit: () => {
  },
  showNotification: () => {
  }
};

export default ListTable;

// region STYLES

const HeaderWrap = styled.div`
    display: flex;

    & > div:first-child {
        margin-right: auto;
    }
`;

// endregion
