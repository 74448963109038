/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name field is required.';
  }

  return errors;
};

export default validate;
