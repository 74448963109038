/* eslint-disable */

import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';


const validate = async (values) => {
  await sleep(100);
  const errors = {};

  if (!values.transaction_type) {
    errors.transaction_type = 'Transaction Type field is required.';
  }
  if (!values.transaction_category_id) {
    errors.transaction_category_id = 'Transaction Category field is required.';
  }
  if (!values.property_id) {
    errors.property_id = 'Name field is required.';
  }
  if (!values.unit_id) {
    errors.unit_id = 'Name field is required.';
  }
  if (!values.payment_date) {
    errors.payment_date = 'Name field is required.';
  }
  if (!values.payment_method) {
    errors.payment_method = 'Name field is required.';
  }

  return errors;
};

export default validate;
