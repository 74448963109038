import { React, useState, useEffect, useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { LEASE_UNITS } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import { Button } from '@/shared/components/Button';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { BasicNotification, useNotification } from '../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { Status } from '../../shared/constants/commonConstnt';
import { useHistory } from 'react-router';
import { AuthComponents } from '../../shared/components/ProtectedRoute';
import { UserRoles as UserRole, UserRoles } from '../../shared/constants/userroles';
import UseAuth from '../../shared/components/account/auth/UseAuth';

export const Lease = () => {
  const module = 'Lease';
  const token = getAccessToken();
  const currentAuth = UseAuth();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
  const history = useHistory();

  const loadData = async () => {
    let urlParams = `?page=${currentPage}&limit=${pageSize}`;
    if (currentAuth?.userData?.role === UserRole.TENANT) {
      urlParams += '&user_id=' + currentAuth?.userData?.id;
    }
    const result = await get(LEASE_UNITS + urlParams, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    if (result) {
      setData([]);//set data does not worked unless set this empty.
      let leaseData = result?.data?.leases.data;
      if (leaseData) {
        leaseData.map((item) => {
          item.status = Status[item.status];
        });
      }
      setData(leaseData);
      setTotalData(result?.data?.leases?.total);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize]);

  const editData = (e, id) => {
    history.push(`edit/${id}`);
  };

  const deleteClickHandle = (e, pTypeId) => {
    setShowHideConfirmBox(pTypeId);
  };

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Lease"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const deleteLease = (leasedProperty) => {
    deleteRequest(LEASE_UNITS + '/' + leasedProperty, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response?.status == 200) {
          loadData();
          setShowHideConfirmBox(false);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });
  };

  const view = (id) => {
    let path = `view/${id}`;
    history.push(path);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'S.N.',
        sortable:false,
        Cell: (cell) => (<>{cell.row.index + 1}</>)
      },
      {
        Header: 'Property',
        accessor: 'property.name',
        sortable: true
      },
      {
        Header: 'Unit',
        accessor: 'unit.unit_name',
        sortable: true
      },
      {
        Header: 'Tenant',
        accessor: 'user.name',
        sortable: true
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        sortable: true
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        sortable: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: true
      },
      {
        Header: 'Actions',
        accessor: '[actionButtons]',
        sortable: false,
        Cell: (cellObj) => (
          <>
            <Button variant="outline-success" size="sm" marginBottom="0"
                    onClick={() => view(cellObj.row.original.id)}>View</Button>
            <AuthComponents AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
              <Button variant="outline-primary" size="sm" marginBottom="0"
                      onClick={(e) => editData(e, cellObj.row.original.id)}>Edit</Button>
              <Button variant="outline-danger" size="sm" marginBottom="0"
                      onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)}>Delete</Button>
            </AuthComponents>
          </>
        )
      }
    ]
  );

  const pageSettings = ({
    total: totalData,
    currentPage,
    limit: pageSize,
    customPagination: true,
  });

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{module}</h3>
        </Col>
      </Row>
      {showHideConfirmBox && (
        <Row>
          <ConfirmBox
            color="primary"
            message="Are you sure you want to delete?"
            showHide={showHideConfirmBox}
            onChangeShowHide={setShowHideConfirmBox}
            callbackConfirm={deleteLease}/>
        </Row>
      )}
      <Row>
        {isFetching && (
          <div className="text-center">
            <TableSpinner animation="border"/>
          </div>
        )}
        {data.length > 0 && !isFetching && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: data,
                pageSettings: pageSettings
              }}
              module={module}
              setPage={(page) => setCurrentPage(page)}
              setLimit={(limit) => setPageSize(limit)}
              showNotification={show}
            />
          </Col>
        )}

        {!data.length && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: [],
                pageSettings: pageSettings
              }}
              module={module}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
