import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserRoles } from '../../../../shared/constants/userroles';
import Settings from '../../../Settings';

export default () => (
  <Switch>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/settings" component={Settings}/>
    </PermissionRoute>
  </Switch>
);
