import { React, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap';
import { get } from '../../../utils/api/base';
import { LEASE_UNITS } from '../../App/Router/api_routs';
import Panel from '../../../shared/components/Panel';
import { useTranslation } from 'react-i18next';
import { getAccessToken } from '../../../utils/helpers';
import {
  TabsWrap, NavLink, NavItem, BorderedBottomTabs,
} from '@/shared/components/Tabs';
import { ViewPanel } from '../../../shared/components/CustomStyle';
import { feeTypes, frequencies, percentageTypes, ucFirst, valueTypes } from '../../../shared/helpers';

const ViewLease = () => {
  const { t } = useTranslation('common');
  const token = getAccessToken();
  const { id } = useParams();
  const history = useHistory();
  const [leased, setLeased] = useState([]);
  const [assetsURL, setAssetsURL] = useState('');

  useEffect(async () => {
    let leasedProperty = await get(LEASE_UNITS + `/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    const assets_url = leasedProperty.data.assets_url;
    setAssetsURL(assets_url);
    leasedProperty = leasedProperty ? leasedProperty.data.property_lease : '';
    setLeased(leasedProperty);
  }, []);

  let primaryAddress = [leased?.user?.user_details?.primary_address_line1, leased?.user?.user_details?.primary_address_line2, leased?.user?.user_details?.primary_city, leased?.user?.user_details?.primary_province?.province_name];
  primaryAddress = primaryAddress.filter(function (element) {
    return element !== null;
  });
  primaryAddress = primaryAddress ? primaryAddress.join(', ') : '';

  const secondaryName = leased?.user?.user_details?.secondary_name;
  let secondaryAddress = [leased?.user?.user_details?.secondary_address_line1, leased?.user?.user_details?.secondary_address_line2, leased?.user?.user_details?.secondary_city, leased?.user?.user_details?.secondary_province?.province_name];
  secondaryAddress = secondaryAddress.filter(function (element) {
    return element !== null;
  });
  secondaryAddress = secondaryAddress ? secondaryAddress.join(', ') : '';

  return (
    <ViewPanel>
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('Leased Details')}</h3>
          </Col>
        </Row>
        <Row>
          <Panel xs={12} md={12} lg={12} title={t('Property Information')}>
            <BorderedBottomTabs>
              <Tab.Container defaultActiveKey="1">
                <TabsWrap>
                  <Nav className="nav-tabs">
                    <NavItem>
                      <NavLink eventKey="1">{t('Property Information')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="2">{t('Leased Unit')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="3">{t('Tenant Details')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="4">{t('Fees')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="5">{t('Utilities')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="6">{t('Documents')}</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink eventKey="7">{t('Payment Settings')}</NavLink>
                    </NavItem>
                  </Nav>
                  <Tab.Content className="typography-message">
                    <Tab.Pane eventKey="1">
                      <p>
                        <strong>{t('Property Name')} : </strong>
                        <span>{leased?.property?.name}</span>
                      </p>
                      <p>
                        <strong>{t('Property Address')} : </strong>
                        <span>{leased?.property?.address1}, {leased?.property?.city}</span>
                      </p>
                      <p>
                        <strong>{t('Property Built Year')} : </strong>
                        <span>{leased?.property?.property_built_year}</span>
                      </p>
                      <p>
                        <strong>{t('Property Total Area')} : </strong>
                        <span>{leased?.property?.property_total_area}</span>
                      </p>
                      <p>
                        <strong>{t('Property Type')} : </strong>
                        <span>{leased?.property?.property_type?.property_type}</span>
                      </p>
                      <p>
                        <strong>{t('Total Floors')} : </strong>
                        <span>{leased?.property?.total_floors}</span>
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <p>
                        <strong>{t('Unit Name')} : </strong>
                        <span>{leased?.unit?.unit_name}</span>
                      </p>
                      <p>
                        <strong>{t('Unit Area')} : </strong>
                        <span>{leased?.unit?.total_area} {t('sqft')}</span>
                      </p>
                      <p>
                        <strong>{t('Rent')} : </strong>
                        <span>{leased?.amount ? (leased?.amount).toFixed(2) : 0.00}</span>
                      </p>
                      <p>
                        <strong>{t('Deposit Amount')} : </strong>
                        <span>{leased?.deposit_amount ? (leased?.deposit_amount).toFixed(2) : 0.00} ({leased?.deposit_type ? ucFirst(leased?.deposit_type) : ''})</span>
                      </p>
                      <p>
                        <strong>{t('Leased Start')} : </strong>
                        <span>{leased?.start_date}</span>
                      </p>
                      <p>
                        <strong>{t('Leased End')} : </strong>
                        <span>{leased?.end_date}</span>
                      </p>
                      <p>
                        <strong>{t('Generate Invoice on (Day of Month)')} : </strong>
                        <span>{leased?.generate_invoice_on}</span>
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <p>
                        <strong>{t('Name')} : </strong>
                        <span>{leased?.user?.name}</span>
                      </p>
                      <p>
                        <strong>{t('Email')} : </strong>
                        <span>{leased?.user?.email}</span>
                      </p>
                      <p>
                        <strong>{t('Address')} : </strong>
                        <span>{primaryAddress}</span>
                      </p>
                      <p>
                        <strong>{t('Phone')} : </strong>
                        <span>{leased?.user?.user_details?.primary_contact_number}</span>
                      </p>
                      <p>
                        <strong>{t('Zip')} : </strong>
                        <span>{leased?.user?.user_details?.primary_zip_code}</span>
                      </p>
                      {secondaryName &&
                        <>
                          <p>
                            <strong>{t('Secondary Name')} : </strong>
                            <span>{secondaryName}</span>
                          </p>
                          {secondaryAddress &&
                            <p>
                              <strong>{t('Secondary Address')} : </strong>
                              <span>{secondaryAddress}</span>
                            </p>
                          }
                          {leased?.user?.user_details?.secondary_contact_number &&
                            <p>
                              <strong>{t('Secondary Phone')} : </strong>
                              <span>{leased?.user?.user_details?.secondary_contact_number}</span>
                            </p>
                          }
                        </>
                      }
                      {leased?.user?.user_details?.documents.length > 0 &&
                        <div>
                          <h3 className="page-title pt-2">{t('Documents')}</h3>
                          {leased?.user?.user_details?.documents.map((item, index) => (
                            <p>
                              <strong>{item?.document_types?.document_type} :</strong>
                              <span><img src={assetsURL + '/' + item?.document} style={{
                                width: '65px',
                                paddingLeft: '20px'
                              }} alt=""/></span>
                            </p>
                          ))}
                        </div>
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      {leased?.fees?.length > 0 &&
                        <Table responsive bordered>
                          <thead>
                          <tr>
                            <th>{t('Fee Type')}</th>
                            <th>{t('Value Type')}</th>
                            <th>{t('Percentage Type')}</th>
                            <th>{t('Utility')}</th>
                            <th>{t('Value')}</th>
                            <th>{t('Grace Period (Days)')}</th>
                            <th>{t('Frequency')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {leased?.fees.map((item, index) => (
                            <tr>
                              <td>
                                {feeTypes.find(function (fee) {
                                  return fee.value === item?.fee_type;
                                }).label}
                              </td>
                              <td>
                                {valueTypes.find(function (vType) {
                                  return vType.value === item?.fee_value_type;
                                }).value}
                              </td>
                              <td>
                                {
                                  item?.percentage_type
                                  ? percentageTypes.find(function (pt) {
                                    return pt.value === item?.percentage_type;
                                  })?.label
                                  : '-'
                                }
                              </td>
                              <td>
                                {item?.utility_id && item?.percentage_type === 'on_utility' ? item.facility.facility : '-'}
                              </td>
                              <td> {item?.fee_value}</td>
                              <td> {item?.grace_period}</td>
                              <td> {frequencies.find(function (f) {
                                return f.value === item?.frequency;
                              }).label}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="5">
                      {leased?.utilities?.length > 0 &&
                        <Table responsive bordered>
                          <thead>
                          <tr>
                            <th>{t('Utility')}</th>
                            <th>{t('Initial Unit')}</th>
                            <th>{t('Minimum Charge')}</th>
                            <th>{t('Variable Charge')}</th>
                            <th>{t('Fixed Charge')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {leased?.utilities.map((item, index) => (
                            <tr>
                              <td>{item?.facility?.facility}</td>
                              <td>{item?.initial_unit}</td>
                              <td>{item?.minimum_charge}</td>
                              <td>{item?.variable_charge}</td>
                              <td>{item?.fixed_charge ? item?.fixed_charge : '-'}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="6">
                      {leased?.documents?.length > 0 &&
                        <Table responsive bordered>
                          <thead>
                          <tr>
                            <th>{t('Document Type')}</th>
                            <th>{t('Document')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {leased?.documents.map((item, index) => (
                            <tr>
                              <td>{item?.document_types?.document_type}</td>
                              <td><img src={item?.document_url} style={{
                                width: '65px',
                                paddingLeft: '20px'
                              }} alt=""/></td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="7">
                      {leased?.payment_settings?.length > 0 &&
                        <Table responsive bordered>
                          <thead>
                          <tr>
                            <th>{t('Payment Method')}</th>
                            <th>{t('QR')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {leased?.payment_settings.map((item, index) => (
                            <>
                              <tr>
                                <td>{item?.payment_method?.name}</td>
                                <td>{item?.payment_method?.file &&
                                  <img src={assetsURL + '/' + item?.payment_method?.file} style={{
                                    width: '200px'
                                  }} alt=""/>}</td>
                              </tr>
                              <tr>
                                <td>{t('Details')}</td>
                                <td>{item?.payment_method?.details}</td>
                              </tr>
                            </>
                          ))}
                          </tbody>
                        </Table>
                      }
                    </Tab.Pane>
                  </Tab.Content>
                </TabsWrap>
              </Tab.Container>
            </BorderedBottomTabs>
          </Panel>
        </Row>
      </Container>
    </ViewPanel>
  );
};

export default ViewLease;
