import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserList } from '../../../Users/index';
import AddUser from '../../../Users/Components/add';
import { UserRoles } from '../../../../shared/constants/userroles';

export default () => (
  <Switch>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/users/list" component={UserList}/>
      <Route path="/users/add" component={AddUser}/>
      <Route path="/users/edit/:id" component={AddUser}/>
    </PermissionRoute>
  </Switch>
);
