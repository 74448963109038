/* eslint-disable */

import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';

const validate = async (values) => {
  await sleep(100);
  const errors = {};
  if (!values.property) {
    errors.property = 'Name field is required.';
  }
  if (!values.unit) {
    errors.unit = 'Name field is required.';
  }
  /***************************** facility validation start ***********************************/
  if (!values.facilities || !values.facilities.length) {
    errors.facilities = 'Required at least one facility.';
  }
  if (values.facilities) {
    const facilitiesErrors = [];

    values.facilities.forEach((facility, index) => {
      const facilityErrors = {};

      if (!facility.facility_id) {
        facilityErrors.facility_id = 'Facility field is Required.';
      }

      if (Object.keys(facilityErrors).length) {
        facilitiesErrors[index] = facilityErrors;
      }
    });
    if (facilitiesErrors.length) {
      errors.facilities = facilitiesErrors;
    }
  }
  /***************************** facility validation end ***********************************/

  return errors;
};

export default validate;
