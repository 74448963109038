import { createContext, useEffect, useRef, useState } from "react";

export const State = createContext();

export default function StateContext({ children }) {
  const [invoice, setInvoice] = useState("");
  const [total, setTotal] = useState("");

  const componentRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  const context = {
    invoice,
    setInvoice,
    componentRef,
    handlePrint,
  };

  return <State.Provider value={context}>{children}</State.Provider>;
}
