import { React, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { documentTypeCreate, documentTypeUpdate } from '../../../utils/handleSubmit';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from '../../../utils/api/base';
import { GET_DOCUMENT_TYPE_DETAILS } from '../../App/Router/api_routs';
import { generateFormInitialValue } from '../../../shared/helpers';

const AddDocumentType = () => {

  const token = getAccessToken();
  const { id } = useParams();
  const [initialValue, setInitialValue] = useState([]);

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Document Type"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const handleAddDocumentType = async (values, form) => {
    let data = [];
    let convertIntKeys = ['status'];
    Object.keys(values)
      .forEach(function (key) {
        if (typeof (values[key]) === 'object' && (values[key]) !== null) {
          data[key] = values[key].value;
          if (convertIntKeys.includes(key)) {
            data[key] = parseInt(values[key].value);
            if (2 == data[key]) {
              data[key] = 0;
            }
          }
        } else {
          data[key] = values[key];
          if (convertIntKeys.includes(key)) {
            data[key] = parseInt(values[key]);
          }
        }
      });
    data = { ...data };
    if (id) {
      documentTypeUpdate(data, token)
        .then((result) => {
          if (result.data.status == 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    } else {
      documentTypeCreate(data, token)
        .then((result) => {
          if (result.data.status == 201) {
            show('success', result.data.message);
            form.reset();
            for (let key in data) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }
  };

  useEffect(async () => {
    if (id) {
      let documentTypeDetails = await get(GET_DOCUMENT_TYPE_DETAILS + `/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      });
      documentTypeDetails = documentTypeDetails ? (documentTypeDetails.data.document_type) : '';
      if (documentTypeDetails) {
        let data = generateFormInitialValue(documentTypeDetails);
        setInitialValue(data);
      }
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Document Type Add Form</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Add Document Type</CardTitle>
                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
              </CardTitleWrap>
              <Form onSubmit={(values, e) => handleAddDocumentType(values, e)}
                    initialValues={initialValue}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  touched
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>Document Type</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="document_type"
                            component={FormField}
                            type="text"
                            placeholder="Document Type"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Status</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="status"
                            component={renderSelectField}
                            options={[{
                              value: 1,
                              label: 'Active'
                            }, {
                              value: 2,
                              label: 'Inactive'
                            }]}
                            placeholder="Status"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddDocumentType;
