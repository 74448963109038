import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProfileMain from './components/ProfileMain';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import { getAccessToken } from '../../../utils/helpers';
import UseAuth from '../../../shared/components/account/auth/UseAuth';
import { getCountriesList, getCurrentUserDetails, getProvincesList } from '../../../shared/helpers/getDatas';
import axios from 'axios';
import { prepareData } from '../../../shared/helpers';
import { profilePictureSave } from '../../../utils/handleSubmit';
import { useSelector } from 'react-redux';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';

const Calendar = () => {
  const token = getAccessToken();
  const user = UseAuth();
  const [details, setDetails] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [source, setSource] = useState('');

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const avatarSave = (values) => {
    console.log(values);
  };

  const handleChange = (file) => {
    let data = {
      user_id: parseInt(details.id),
      file: file[0]
    };
    const profileData = prepareData(data, ['document_type', 'user_id']);
    const formData = profileData.form_data;
    profilePictureSave(formData, token)
      .then((result) => {
        if (result.data.status === 200) {
          show('success', result.data.message);
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });
  };

  useEffect(() => {
    const { CancelToken } = axios;
    setSource(CancelToken.source());
    setIsFetching(true);
    getCountriesList(token, source, setCountries);
    getProvincesList(token, source, setProvinces);
    getCurrentUserDetails(token, user)
      .then(function (res) {
        if (res) {
          setDetails(res.data.user_details);
          setIsFetching(false);
        }
      });
  }, []);
  return (
    <Container>
      {!isFetching ?
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain onSubmit={avatarSave} userData={details} handleChange={handleChange}/>
              <ProfileCalendar/>
              {/*<ProfileTasks />*/}
            </Row>
          </Col>
          <ProfileTabs userData={details} countries={countries} provinces={provinces} token={token} source={source}/>
        </Row>
        : ''
      }
    </Container>
  );
};

export default Calendar;
