import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
  FormHalfContainer,
  FormGroupDescription,
  FormHalfWrap,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import renderFileInputField from '@/shared/components/form/FileInput';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import axios from 'axios';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { DELETE_DOCUMENTS } from '../../App/Router/api_routs';
import { deleteRequest, get } from '../../../utils/api/base';
import { getAccessToken } from '../../../utils/helpers';
import validate from './validate';
import { UserRoles as UserRole, UserRoles } from '../../../shared/constants/userroles';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import {
  documentSave,
  userCreate,
  userUpdate,
} from '../../../utils/handleSubmit';
import { prepareData, range, replaceAll } from '../../../shared/helpers';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import Error from '../../../shared/components/form/Error';
import ConfirmBox from '../../../shared/components/Modal/confirm';
import {
  getCountriesList,
  getDocumentTypeList,
  getProvincesList,
  getRolesList,
  getUserDetails,
} from '../../../shared/helpers/getDatas';

const AddUser = () => {
  const token = getAccessToken();
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoleOption, setSelectedRoleOption] = useState();
  const [showSection, setShowSection] = useState(false);
  const { id } = useParams();
  const [initValue, setInitValue] = useState({
    primary_country_id: {
      value: 524,
      label: 'Nepal'
    },
    secondary_country_id: {
      value: 524,
      label: 'Nepal'
    }
  });
  const [showDocumentDeleteConfirmBox, setShowDocumentDeleteConfirmBox] = useState(false);

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handleChange = (selectedOption) => {
    const adminRoles = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN];
    setShowSection(false);
    if (parseInt(selectedOption.value) === UserRoles.TENANT) {
      setShowSection(true);
    }
  };

  const saveDocument = (documents, user_id, form) => {
    form = form || null;
    documents.forEach(function (document) {
      document.user_id = parseInt(user_id);
      const documentData = prepareData(document, ['document_type', 'user_id']);
      const formData = documentData.form_data;
      const docData = { ...(documentData.data) };
      documentSave(formData, token)
        .then((result) => {
          if (result.data.status === 201) {
            //show('success', result.data.message);
            /*if (form && !id) {
              form.reset();
              for (const key in docData) {
                form.resetFieldState(key);
              }
            }*/
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    });
  };

  const handleUserDetailSubmit = async (values, form) => {
    let userData = [];
    const { documents } = values;
    const convertIntKeys = ['primary_country_id', 'primary_state_id', 'secondary_country_id'];
    const excludeKeys = ['documents'];
    userData = prepareData(values, convertIntKeys, excludeKeys).data;
    userData = { ...userData };

    if (userData && !id) {
      await userCreate(userData, token)
        .then((result) => {
          if (result.data.status === 201) {
            if (userData.role === UserRole.TENANT) {
              const user_id = result.data.user_detail.user_id;
              saveDocument(documents, user_id, form);
            }
            show('success', result.data.message);
            form.reset();
            for (const key in userData) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        })
        .catch(function (e) {
          show('danger', e.response.data.message);
        });
    }
    if (userData && id) {
      await userUpdate(id, userData, token)
        .then((result) => {
          if (result.data.status === 200) {
            if (userData.role === UserRole.TENANT) {
              const user_id = result.data.user_detail.user_id;
              saveDocument(documents, user_id, form);
            }
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }
  };
  const deleteDocument = (fields, i) => {
    if (fields.value[i].id) {
      setShowDocumentDeleteConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
    }
  };

  const confirmDeleteDocument = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    deleteRequest(DELETE_DOCUMENTS + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowDocumentDeleteConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    await getCountriesList(token, source, setCountries);
    await getProvincesList(token, source, setProvinces);
    await getRolesList(token, source, setRoles, setSelectedRoleOption, setShowSection);
    await getDocumentTypeList(token, source, setDocumentTypes);
    if (id) {
      await getUserDetails(token, id, source, setShowSection, setInitValue);
    }
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">User Add Form</h3>
          {/* <h3 className="page-subhead subhead">Use these elements, if you want to show some hints or additional
                        information
                    </h3> */}
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <div className="form-wrap" data-token={token}>
            <Form
              onSubmit={(values, e) => handleUserDetailSubmit(values, e)}
              initialValues={initValue}
              mutators={{ ...arrayMutators }}
              validate={validate}
            >
              {({
                handleSubmit,
                form,
                errors,
                reset,
              }) => {
                const mapObj = {
                  '[': '',
                  ']': '',
                  '{': '',
                  '}': ''
                };
                const documentError = typeof errors.documents === 'string' ? replaceAll(JSON.stringify(errors.documents, 0, 2), mapObj) : '';
                return (
                  <FormContainer onSubmit={handleSubmit}>
                    <Card id="info-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Primary Contact Person Information</CardTitle>
                          {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                        </CardTitleWrap>
                        <FormGroup>
                          <FormGroupLabel>Name</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_name"
                              component={FormField}
                              type="text"
                              placeholder="Name"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Email</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_email"
                              component={FormField}
                              type="email"
                              placeholder="Email"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Contact Number</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_contact_number"
                              component={FormField}
                              type="number"
                              placeholder="Phone Number"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Address Line 1</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_address_line1"
                              component={FormField}
                              type="text"
                              placeholder="Address Line 1"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Address Line 2</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_address_line2"
                              component={FormField}
                              type="text"
                              placeholder="Address Line 2"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Country</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_country_id"
                              component={renderSelectField}
                              options={countries}
                              placeholder="Country"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>State</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_state_id"
                              component={renderSelectField}
                              options={provinces}
                              placeholder="State"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>City</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_city"
                              component={FormField}
                              type="text"
                              placeholder="City"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Zip Code</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="primary_zip_code"
                              component={FormField}
                              type="text"
                              placeholder="Zip Code"
                            />
                          </FormGroupField>
                        </FormGroup>
                        {id && <FormGroup>
                          <FormGroupLabel>Status</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="status"
                              component={renderSelectField}
                              options={[{
                                value: 1,
                                label: 'Active'
                              }, {
                                value: 0,
                                label: 'Inactive'
                              }]}
                              placeholder="Status"
                            />
                          </FormGroupField>
                        </FormGroup>}
                      </CardBody>
                    </Card>
                    <Card id="secondary-info-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Secondary Contact Information</CardTitle>
                        </CardTitleWrap>
                        <FormGroup>
                          <FormGroupLabel>Name</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_name"
                              component={FormField}
                              type="text"
                              placeholder="Name"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Contact Number</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_contact_number"
                              component={FormField}
                              type="number"
                              placeholder="Phone Number"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Address Line 1</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_address_line1"
                              component={FormField}
                              type="text"
                              placeholder="Address Line 1"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Address Line 2</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_address_line2"
                              component={FormField}
                              type="text"
                              placeholder="Address Line 2"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Country</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_country_id"
                              component={renderSelectField}
                              options={countries}
                              placeholder="Country"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>State</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_state_id"
                              component={renderSelectField}
                              options={provinces}
                              placeholder="State"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>City</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_city"
                              component={FormField}
                              type="text"
                              placeholder="City"
                            />
                          </FormGroupField>
                        </FormGroup>
                        <FormGroup>
                          <FormGroupLabel>Zip Code</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="secondary_zip_code"
                              component={FormField}
                              type="text"
                              placeholder="Zip Code"
                            />
                          </FormGroupField>
                        </FormGroup>
                      </CardBody>
                    </Card>
                    <Card id="roles-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Roles</CardTitle>
                        </CardTitleWrap>
                        <FormGroup>
                          <FormGroupLabel>Choose Roles</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              component={renderSelectField}
                              name="role"
                              defaultValue={selectedRoleOption}
                              onSelectChange={handleChange}
                              options={roles}
                              placeholder="Select Roles"
                            />
                          </FormGroupField>
                        </FormGroup>
                      </CardBody>
                    </Card>
                    {
                      showSection
                        ? (
                          <>
                            <Card id="document-card">
                              <CardBody>
                                <CardTitleWrap>
                                  <CardTitle>Documents</CardTitle>
                                </CardTitleWrap>
                                {showDocumentDeleteConfirmBox && (
                                  <Row>
                                    <ConfirmBox
                                      color="primary"
                                      message="Are you sure you want to delete?"
                                      showHide={showDocumentDeleteConfirmBox}
                                      onChangeShowHide={setShowDocumentDeleteConfirmBox}
                                      callbackConfirm={confirmDeleteDocument}/>
                                  </Row>
                                )}
                                <FieldArray name="documents">
                                  {({ fields }) => {
                                    return (
                                      <>
                                        {
                                          fields.map((name, i) => {
                                            return (
                                              <FormHalfWrap key={i}>
                                                <FormHalfContainer>
                                                  <FormGroup>
                                                    <FormGroupLabel>Document Type</FormGroupLabel>
                                                    <FormGroupField>
                                                      <Field
                                                        name={`${name}.document_type`}
                                                        component={renderSelectField}
                                                        options={documentTypes}
                                                        placeholder="Select Document Type"
                                                      />
                                                    </FormGroupField>
                                                  </FormGroup>
                                                </FormHalfContainer>
                                                <FormHalfContainer>
                                                  <FormGroup>
                                                    <FormGroupLabel>Add file</FormGroupLabel>
                                                    <FormGroupField>
                                                      <Field
                                                        name={`${name}.document`}
                                                        component={renderFileInputField}
                                                        preview={fields.value[i]?.document_url}
                                                      />
                                                      {
                                                        (i != 0)
                                                          ? (
                                                            <Link href={void (0)} onClick={() => {
                                                              deleteDocument(fields, i);
                                                            }}>
                                                              <CloseCircleOutlineIcon/>
                                                            </Link>
                                                          ) : ''
                                                      }
                                                    </FormGroupField>
                                                  </FormGroup>
                                                </FormHalfContainer>
                                              </FormHalfWrap>
                                            );
                                          })
                                        }
                                        <FormHalfContainer>
                                          <Button
                                            size="sm"
                                            variant="success"
                                            onClick={() => fields.push({})}
                                          >
                                            <PlusCircleOutlineIcon/> <span>Add Document</span>
                                          </Button>
                                          {documentError ? <Error error={documentError}/> : ''}
                                        </FormHalfContainer>
                                      </>
                                    );
                                  }}
                                </FieldArray>
                              </CardBody>
                            </Card>
                          </>
                        )
                        : null
                    }
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">Submit</Button>
                      <Button variant="secondary" type="button" onClick={reset}>
                        Cancel
                      </Button>
                    </FormButtonToolbar>
                  </FormContainer>
                );
              }}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default AddUser;
