/* eslint-disable */

import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import { VERIFY_CURRENT_PASSWORD } from '../../../App/Router/api_routs';
import { post } from '../../../../utils/api/base';

const checkCurrentPassword = async (api_url, values) => {
  const auth = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
  const token = auth?.accessToken;
  // let url = api_url + `?id=${auth.userData.id}&password="${currenPassword}"`;
  values.id = auth.userData.id;
  const response = await post(api_url,values, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  return response?.data?.verified;
};

const validate = async (values) => {
  const errors = {};
  if (!values.current_password) {
    errors.current_password = 'Current password field is required';
  }
  if (values.current_password) {
    const res = await checkCurrentPassword(VERIFY_CURRENT_PASSWORD, values);
    if (res === false) {
      errors.current_password = 'Current password does not match.';
    }
  }
  if (!values.password) {
    errors.password = 'New password field is required.';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Confirm password field is required.';
  }
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Confirm password does no matched';
  }
  await sleep(200);
  return errors;
};

export default validate;
