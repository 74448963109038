import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UseAuth from './account/auth/UseAuth';
import Unauthorized from '../../containers/DefaultPage/Unauthorized';

export function ProtectedRoute({
  children,
  // ...rest
}) {
  const auth = UseAuth();
  return (
    auth.accessToken ? children : <Redirect to="/login" />
  );
}

export default ProtectedRoute;

export function PermissionRoute({
  AllowRoles,
  children,
}) {
  const currentAuth = UseAuth();
  return (
    (currentAuth && currentAuth.userData && AllowRoles.includes(currentAuth.userData.role))
      ? children : <Route path={children.path} component={Unauthorized} />
  );
}

export const AuthComponents = ({
  AllowRoles,
  children,
}) => {
  const currentAuth = UseAuth();
  return (
    (currentAuth && currentAuth.userData && AllowRoles.includes(currentAuth.userData.role)) ? children : ''
  );
};
