import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import { Card } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { colorAdditional, colorBlue, colorBorder } from '@/utils/palette';
import { paddingLeft, left } from '@/utils/directions';
import { ProfileCard } from '../ProfileBasicComponents';
import { Field, Form } from 'react-final-form';
import { FormButtonToolbar, FormContainer } from '@/shared/components/form/FormElements';
import renderDropZoneField from '@/shared/components/form/dropzones/DropZone';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProfileMain = ({
  onSubmit,
  userData,
  handleChange
}) => {
  const [displayForm, setDisplayForm] = useState('none');
  const [hasAvatar, setHasAvatar] = useState(false);
  const profileRef = useRef(null);
  const handleOnMouseIn = () => {
    setDisplayForm('block')
  }
  const handleOnMouseOut = () => {
    setDisplayForm('none')
  }

  const handleOnClick = () => {
    setHasAvatar(false);
    setDisplayForm('block');
    profileRef.current?.click();
  }

  useEffect(() => {
    if(userData?.user_details?.avatar_url){
      setHasAvatar(true);
    }
    if(!hasAvatar){
      setDisplayForm('block');
    }
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ProfileCard>
          <ProfileInformation>
            <AvatarWrap>
              <ProfileAvatar className="profile-avatar">
                {hasAvatar &&
                  <AvatarImageWrap onMouseOver={handleOnMouseIn} onMouseLeave={handleOnMouseOut} onClick={handleOnClick}>
                    <span className="lnr lnr-upload profile-upload-cons" style={{display:displayForm}} onMouseOver={handleOnMouseIn} onMouseLeave={handleOnMouseOut}/>
                    <img src={userData?.user_details?.avatar_url} alt="avatar"/>
                  </AvatarImageWrap>
                }
                <div className="form-wrap" style={{ display: displayForm }}>
                  <Form onSubmit={onSubmit}>
                    {({
                      handleSubmit,
                      form
                    }) => (
                      <FormContainer onSubmit={handleSubmit}>
                        <Field
                          name="files"
                          component={renderDropZoneField}
                          onFileChange={handleChange}
                          className="profile-avatar-field"
                          avatarRef={profileRef}
                        />
                      </FormContainer>
                    )}
                  </Form>
                </div>
              </ProfileAvatar>
            </AvatarWrap>
            <ProfileData>
              <ProfileName>{userData?.name}</ProfileName>
              <ProfileWork>{userData?.user_role?.name}</ProfileWork>
              <ProfileContact>{userData?.user_details?.primary_contact_number}</ProfileContact>
              <ProfileContact dir="ltr">{userData?.user_details?.secondary_contact_number}</ProfileContact>
              {/*<ProfileButton variant="primary">
              <MessageTextOutlineIcon /> <span>Message</span>
            </ProfileButton>*/}
            </ProfileData>
          </ProfileInformation>
          {/*<ProfileStats>
          <ProfileStat>
            <ProfileStatNumber>05</ProfileStatNumber>
            <ProfileStatTitle>Projects</ProfileStatTitle>
          </ProfileStat>
          <ProfileStat>
            <ProfileStatNumber>24</ProfileStatNumber>
            <ProfileStatTitle>Tasks</ProfileStatTitle>
          </ProfileStat>
          <ProfileStat>
            <ProfileStatNumber>12</ProfileStatNumber>
            <ProfileStatTitle>Reports</ProfileStatTitle>
          </ProfileStat>
        </ProfileStats>*/}
        </ProfileCard>
      </Card>
    </Col>
  );
};

export default ProfileMain;

// region STYLES

const ProfileInformation = styled.div`
    padding: 30px 40px;
    display: flex;
    text-align: ${left};
    border-bottom: 1px solid ${colorBorder};

    @media (max-width: 1345px) and (min-width: 1200px) {
        padding: 30px 15px;
    }

    @media screen and (max-width: 360px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;

const ProfileAvatar = styled.div`
    height: 140px;
    width: 140px;
    overflow: hidden;
    border-radius: 50%;

    img {
        height: 100%;
    }

    @media (max-width: 1345px) and (min-width: 1200px) {
        height: 130px;
        width: 130px;
    }
`;

const ProfileData = styled.div`
    width: calc(100% - 140px);
    ${paddingLeft}: 25px;

    @media screen and (max-width: 360px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }
`;

const ProfileName = styled.p`
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    line-height: 18px;
`;

const ProfileWork = styled.p`
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0;
    opacity: 0.6;
    line-height: 18px;
`;

const ProfileContact = styled.p`
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 18px;
`;

const ProfileButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 0;
    padding: 8px 15px;
`;

const ProfileStats = styled.div`
    display: flex;
    justify-content: space-around;
`;

const ProfileStat = styled.div`
    text-align: center;
    padding-top: 5px;
    padding-bottom: 15px;
`;

const ProfileStatNumber = styled.p`
    color: ${colorBlue};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
`;

const ProfileStatTitle = styled.p`
    margin: 0;
    color: ${colorAdditional};
    font-size: 12px;
    line-height: 14px;
`;

const AvatarWrap = styled.div`

    .profile-avatar {
        border: 1px solid #e3e3e3;
    }

    .dropzone-input {
        height: 140px !important;
        min-height: 140px !important;
        top: 0;
        left: 15px;
    }

    .dropzone-image {
        width: 145px !important;
        height: 145px !important;
        position: absolute;
        top: -4px;
        left: -4px;

        img {
            padding: 0;
        }
    }
`;

const AvatarImageWrap = styled.span`
    position: relative;
    cursor: pointer;

    .profile-upload-cons {
        position: absolute;
        top: 0;
        left: 44%;
        color: #222222;
        z-index: 9;
        background-color: #ffffff82;
        padding: 5px;
        font-weight: 700;
        border-radius: 5px;
    }
`;
// endregion
