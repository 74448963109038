//export const BASE_URL = 'https://rb-api.property-nepal.com';
//export const BASE_URL = 'https://aspire-api.property-nepal.com';
//export const BASE_URL = 'https://yachuhome-api.property-nepal.com';
export const BASE_URL = 'https://dev-api.property-nepal.com';
//export const BASE_URL = 'http://local.property-management-api.com';
export const END_POINT = `${BASE_URL}/api/`;
export const REGISTER = 'register';
export const LOGIN_USER = 'login';
export const LOGOUT_USER = 'logout';

export const DASHBOARD = 'dashboard';

//SETTINGS
export const SAVE_SETTINGS = 'save_settings';
export const GET_SETTINGS = 'get_settings';

// SuperAdmin Access
// ROLES
export const GET_ROLES = 'roles';
export const SAVE_ROLE = 'roles/store';
export const UPDATE_ROLE = 'roles/update';
export const DELETE_ROLE = 'roles/delete';

// PERMISSIONS
export const GET_PERMISSIONS = 'permissions';
export const SAVE_PERMISSION = 'permissions/store';
export const UPDATE_PERMISSION = 'permissions/update';
export const DELETE_PERMISSION = 'permissions/delete';
export const ASSIGN_PERMISSION_TO_ROLE = 'roles/{role}/assign-permission';
export const REVOKE_PERMISSION_FROM_ROLE = 'roles/{role}/revoke-permission';

// USERS
export const GET_USERS = 'users';
export const GET_USER_DETAILS = 'users/details/';
export const GET_USER_DETAILS_BY = 'users/detail-by/';
export const SAVE_USER = 'users/store';
export const UPDATE_USER = 'users/update';
export const DELETE_USER = 'users/delete';
export const PROFILE_PICTURE_SAVE = 'users/update_avatar';
export const VERIFY_CURRENT_PASSWORD = 'users/checkCurrentPassword';
export const UPDATE_USER_PASSWORD = 'users/updatePassword';

// USERS PERMISSIONS
export const GET_USER_PERMISSIONS = 'permission-allocations';
export const SAVE_USER_PERMISSIONS = 'permission-allocations/store';
export const UPDATE_USER_PERMISSIONS = 'permission-allocations/update';
export const DELETE_USER_PERMISSIONS = 'permission-allocations/delete';

// COUNTRIES/PROVINCES
export const GET_COUNTRIES = 'countries';
export const GET_PROVINCES = 'provinces';

// DOCUMENT TYPES
export const GET_DOCUMENT_TYPES = 'document_types';
export const SAVE_DOCUMENT_TYPE = 'document_types/add';
export const GET_DOCUMENT_TYPE_DETAILS = 'document_types/getDocumentType';
export const UPDATE_DOCUMENT_TYPE = 'document_types/update';
export const DELETE_DOCUMENT_TYPE = 'document_types/delete';

// PROPERTY TYPE
export const PROPERTY_TYPES = 'property_types';

// PROPERTIES
export const GET_PROPERTIES = 'properties';
export const GET_PROPERTY_DETAILS = 'properties/details/';
export const SAVE_PROPERTY = 'properties/store';
export const UPDATE_PROPERTY = 'properties/update';
export const DELETE_PROPERTY = 'properties/delete';

// PROPERTIES UNITS
export const PROPERTY_UNITS = 'property_units';

// DOCUMENT
export const GET_DOCUMENTS = 'documents';
export const CREATE_DOCUMENTS = 'documents/store';
export const SAVE_DOCUMENTS = 'documents/save';
export const UPDATE_DOCUMENTS = 'documents/update';
export const DELETE_DOCUMENTS = 'documents/delete';

// PROPERTIES UNITS
export const LEASE_UNITS = 'property-leases';
export const SAVE_LEASE_PROPERTY = 'property-leases/save';
export const GET_LEASED_PROPERTY_BY_PROPERTY_UNIT = 'property-leases/getLeaseByPropertyUnit';
export const GET_LEASED_PROPERTY = 'property-leases/getLeasedProperty';
export const FEES = 'fees';
export const SAVE_FEES = 'fees/save';
export const DELETE_FEES = 'fees/destroy';
export const UTILITY_BY_LEASED_ID = 'utilities';
export const SAVE_UTILITY = 'utilities/save';
export const DELETE_UTILITY = 'utilities/destroy';
export const SAVE_PAYMENT_SETTINGS = 'payment-settings/save';
export const DELETE_PAYMENT_SETTINGS = 'payment-settings/destroy';

//FACILITIES
export const FACILITIES = 'facilities';
export const PAYMENT_METHOD = 'payment-method';

//INVOICES
export const INVOICES = 'invoices';
export const GENERATE_INVOICE_NUMBER = 'generate-invoice-number';
export const GENERATE_INVOICE_LATE_DIS = 'generate-invoice-late-dis';
export const INVOICE_DETAILS_STORE = 'invoice_details/store';
export const INVOICE_DETAILS_SAVE = 'invoice_details/save';
export const INVOICE_DETAIL_FORCE_DELETE_BY_INVOICE_ID = 'deleteByInvoiceID';

export const TRANSACTION_CATEGORIES = 'transaction_categories';
export const TRANSACTION_CATEGORIES_BY_TYPE = 'transaction_categories/categories-by-type';
export const TRANSACTIONS = 'transactions';
export const GET_TRANSACTION_BY = 'get-transactions-by';
export const CHECK_ADVANCE_TRANSACTION = 'check-advance';
